import React, { useState } from "react";
import SpecialOffers from "../../components/home/SpecialOffers/SpecialOffers";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import { useGetBrandsQuery } from "../../store/services/brandService";
import BrandCard from "../../components/home/BrandCard/BrandCard";
import { Link } from "react-router-dom";
import AccessoriesCategories from "../../components/home/Categories";

const Accessories = () => {
  const [prevLocation] = useState("");
  const { data: brandsdata, isLoading, error } = useGetBrandsQuery();
  const brands = brandsdata?.brands;

  return (
    <div className="max-w-container mx-auto">
      <Breadcrumbs title="Accessories" prevLocation={prevLocation} />
      <div className="pb-10">
      <AccessoriesCategories/>
      </div>
    </div>
  );
};

export default Accessories;
