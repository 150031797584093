import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import currency  from "currency-formatter";
import { HiOutlineChevronRight } from "react-icons/hi";
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import AccountList from "../../components/AccountList";
import { useGetOrdersQuery } from "../../store/services/userOrdersService";
import { useReceivedOrderMutation } from "../../store/services/userOrdersService";
import { useDispatch, useSelector } from "react-redux";
import { discount } from "../../utils/discount";
import Pagination from "../../components/Pagination";
import Spinner from "../../components/Spinner";
import { useChangePasswordMutation, useGetUserByIdQuery, useUpdateProfileMutation } from "../../store/services/authService";
import { useAddAddressMutation, useGetUserAddressesQuery, useUpdateAddressMutation } from "../../store/services/addressService";
import { useVerifyPaymentQuery } from "../../store/services/paymentService";
import toast, { Toaster } from "react-hot-toast";
import { emptyCart } from "../../store/reducers/cartReducer";
const Profile = () => {
const [selected_tab, setselected_tab] = useState(0)

const { cart, total } = useSelector((state) => state.cart);
let { page } = useParams();
page = page ? page : 1;
const { user } = useSelector((state) => state.authReducer);
const { data, isFetching } = useGetOrdersQuery({userId:user.id});
const [updateOrder, response] = useReceivedOrderMutation();
const orderReceived = (id) => {
  updateOrder(id);
};


const [params] = useSearchParams();
const id = params.get("session_id");
const { data:paydata, isSuccess:paysuccess } = useVerifyPaymentQuery(id, {
  skip: id ? false : true,
});
const dispatch = useDispatch();
const navigate = useNavigate();
useEffect(() => {
  if (paysuccess) {
    localStorage.removeItem("cart");
    toast.success(paydata.msg);
    dispatch(emptyCart());
    navigate("/profile");
  }
}, [paysuccess]);





const [userData, setUserData] = useState({
  name: '',
  email: ''
});

const [formData, setFormData] = useState({
  currentPassword: '',
  newPassword: '',
  confirmNewPassword: ''
});

const [errorMessages, setErrorMessages] = useState([]);
const [successMessage, setSuccessMessage] = useState('');

const { data: userProfile, isSuccess } = useGetUserByIdQuery(user.id);

useEffect(() => {
  if (isSuccess) {
      setUserData({
          name: userProfile.name,
          email: userProfile.email
      });
  }
}, [isSuccess, userProfile]);

const handleInputChange = (e) => {
  if (e.target.name === 'name' || e.target.name === 'email') {
      setUserData({
          ...userData,
          [e.target.name]: e.target.value
      });
  } else {
      setFormData({
          ...formData,
          [e.target.name]: e.target.value
      });
  }
};

const validateInputs = () => {
  const errors = [];

  if (!userData.name.trim()) {
      errors.push('Name is required');
  }

  if (!userData.email.trim()) {
      errors.push('Email is required');
  } else if (!/\S+@\S+\.\S+/.test(userData.email)) {
      errors.push('Invalid email format');
  }

  setErrorMessages(errors);
  return errors.length === 0;
};


const validatepassInputs = () => {
  const errors = [];


  if (!formData.currentPassword.trim() || !formData.newPassword.trim() || !formData.confirmNewPassword.trim()) {
      errors.push('All password fields are required');
  } else if (formData.newPassword !== formData.confirmNewPassword) {
      errors.push('New password and confirm new password must match');
  }

  setErrorMessages(errors);
  return errors.length === 0;
};

const [changePassword, { isLoading: isChangingPassword }] = useChangePasswordMutation();

const handleChangePassword = async () => {
  if (!validatepassInputs()) {
      return;
  }

  await changePassword({
      oldPassword: formData.currentPassword,
      newPassword: formData.newPassword,
      userId: user.id
  }).unwrap()
      .then((result) => setSuccessMessage(result.msg))
      .catch((error) => setErrorMessages(error.data.errors.map(err => err.msg)));
};

const [updateProfile, { isLoading: isUpdatingProfile }] = useUpdateProfileMutation();

const handleUpdateProfile = async () => {
  if (!validateInputs()) {
      return;
  }

  await updateProfile({
      name: userData.name,
      email: userData.email,
      userId: user.id
  }).unwrap()
      .then((result) => setSuccessMessage(result.msg))
      .catch((error) => setErrorMessages(error.data.errors.map(err => err.msg)));
};


const [formaddressData, setFormAddressData] = useState({
  addressLine1: '',
  country: 'India',
  state: '',
  city: '',
  postalCode: '',
  userId: user.id
});

const handleaddressInputChange = (e) => {
  setFormAddressData({
      ...formaddressData,
      [e.target.name]: e.target.value
  });
};

const validateAddressInputs = () => {
  const errors = [];

  if (!formaddressData.addressLine1.trim()) {
      errors.push('Complete Address is required');
  }

  if (!formaddressData.state.trim()) {
      errors.push('State is required');
  }

  if (!formaddressData.city.trim()) {
      errors.push('City is required');
  }

  if (!formaddressData.postalCode.trim()) {
      errors.push('Pincode is required');
  } else if (!/^\d{6}$/.test(formaddressData.postalCode.trim())) {
      errors.push('Pincode must be a 6-digit number');
  }

  setErrorMessages(errors);
  return errors.length === 0;
};

const { data: userAddresses , isLoading: isAddressloading } = useGetUserAddressesQuery(user.id);
const [addAddress, { isLoading: isAddingAddress }] = useAddAddressMutation();
const [updateAddress, { isLoading: isUpdatingAddress }] = useUpdateAddressMutation();


useEffect(() => {
  if (userAddresses && userAddresses.length > 0) {
    const firstAddress = userAddresses[0];
    setFormAddressData({
      ...formaddressData,
      addressLine1: firstAddress.addressLine1,
      state: firstAddress.state,
      city: firstAddress.city,
      postalCode: firstAddress.postalCode,
    });
  }
}, [userAddresses]);

const handleSubmit = async (e) => {
  e.preventDefault();

  if (!validateAddressInputs()) {
      return;
  }

  try {
    if (userAddresses && userAddresses.length > 0) {
      // Update the first address found for the user
      await updateAddress({ id: userAddresses[0]._id, addressData: formaddressData }).unwrap();
      setSuccessMessage('Address updated successfully');
  } else {
      // If no address is found, create a new one
      await addAddress(formaddressData).unwrap();
      setSuccessMessage('Address added successfully');
  }
      setSuccessMessage('Address updated successfully');
      // You can add logic to update the UI after successfully updating the address
  } catch (error) {
      setErrorMessages(error.data.errors.map(err => err.msg));
      console.error(error);
  }
};
  return (
    <>
   <div className="w-full flex flex-col gap-3 p-2 mb-5" style={{backgroundColor:"#f8f8f8"}} >
  
  <p className="text-sm font-normal text-lightText capitalize flex items-center " style={{marginBottom: "0px"}}>

    <span className="px-1 ">
      <HiOutlineChevronRight />
    </span>
    <span className="capitalize fontstyleclass text-primeColor">
      Home / DashBoard
    </span>
  </p>
</div>
<div className="max-w-container mx-auto px-4">
<div className="full-row pt-30" id="prod">
				<div className="container">
					<div className="row mb-4 mb-lg-5 pb-lg-5">
                        <div className="col-xl-3 col-lg-2 col-md-12 mb-lg-0">
                            <ul className=" bg-light dashboard-list sticky" role="tablist" style={{}}>
							<AccountList selected_tab={setselected_tab} />
                            </ul>
                        </div>

                      {selected_tab == 0 ? 
                      
                      !isAddressloading ? <div className="col-xl-9 col-lg-10 col-md-12 content">
                            <div className="tab-content dashboard-content">
                                <div id="dashboard" className="tab-pane fade active show">
                                    <div className="card">
										<div className="card-body">
											<div className="row">
												<div className="col-md-4">
													<div className="box text-center" style={{border: 'solid 1px #ccc',padding: '20px 0px',borderRadius: '10px'}}>
														<p className="mb-0"><i className="fa fa-clock-o text-black" style={{width:'auto',fontSize: '50px'}}></i></p>
														<h3 className="mb-0" style={{fontFamily:' calibri !important'}}>ORDER HISTORY</h3>
														<h1 className="mb-0" style={{fontFamily:' calibri !important'}}>{data?.orders?.length || 0 }</h1>
														<p className="mb-0">Total Orders</p>
														<p className="mb-0"><a onClick={() => setselected_tab(1)}>View All</a></p>
													</div>
												</div>
											
												<div className="col-md-4">
													<div className="box text-center" style={{border: 'solid 1px #ccc',padding: '20px 0px',borderRadius: '10px'}}>
														<p className="mb-0"><i className="fa fa-shopping-cart text-black" style={{width:'auto',fontSize: '50px'}}></i></p>
														<h3 className="mb-0" style={{fontFamily:' calibri !important'}}>MY CART</h3>
														<h1 className="mb-0" style={{fontFamily:' calibri !important'}}>{cart.length}</h1>
														<p className="mb-0">Total items</p>
														<p className="mb-0"><a href="../cart">View All</a></p>
													</div>
												</div>
											</div>
										</div>
									</div>
									
                                    
									<div className="card">
										<div className="card-body">
											<div className="row">
												<div className="col-md-6">
													<div className="box" style={{border: 'solid 1px #ccc',padding: '20px',borderRadius: '10px'}}>
														<h3 className="mb-3">Personal Information</h3>
														<h4 className="mb-0">{userProfile?.name}</h4>
														<p className="mb-0">{userProfile?.email}</p>
														<hr/>
														<p className="mb-0"><a onClick={() => setselected_tab(3)}>Edit Profile</a></p>
													</div>
												</div>
												<div className="col-md-6">
													<div className="box" style={{border: 'solid 1px #ccc',padding: '20px',borderRadius: '10px'}}>
														<h3 className="mb-0">Shipping Address</h3>
																												<h4 className="mb-0">{userProfile?.name}</h4>
														<p className="mb-0">{userProfile?.email}</p>
														<p className="mb-0">{userAddresses[0]?.addressLine1}</p>
														<p className="mb-0">{userAddresses[0]?.city}</p>
														<p className="mb-0">{userAddresses[0]?.state} - {userAddresses[0]?.postalCode}</p>
														<p className="mb-0">{userAddresses[0]?.country}</p>
																												<hr/>
														<p className="mb-0"><a onClick={() => setselected_tab(2)}>Edit Address</a></p>
													</div>
												</div>
											</div>
										</div>
									</div>
                                </div>
                            </div>
                        </div>:<p>Loading...</p>:null}


						{selected_tab == 1 ?  
                data?.orders?.length > 0 ? (
                  <div className="col-xl-9 col-lg-10 col-md-12 content">
                    <div className="table-container">
                      <table className="w-full">
                        <thead>
                          <tr className="thead-tr">
                            <th className="th">image</th>
                            <th className="th">name</th>
                            <th className="th">total</th>
                            <th className="th">details</th>
                            <th className="th">received</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.orders.map((item) => {
                            const total = currency.format(
                              discount(
                                item?.productId?.price,
                                item?.productId?.discount
                              ) * item?.quantities,
                              {
                                code: "INR",
                              }
                            );
                            return (
                              <tr className="even:bg-gray-50" key={item._id}>
                                <td className="td">
                                  <img
                                    src={item?.productId?.images ? `/images/${item?.productId?.images[0]}`:`/images/logo/2.png`}
                                    alt={item?.productId?.title}
                                    className="w-12 h-12 object-cover rounded-full"
                                  />
                                </td>
                                <td className=" td font-medium">
                                  {item?.productId?.title}
                                </td>
                                <td className="td font-bold ">{total}</td>
                                <td className="td">
                                  <Link
                                    to={`/user-order-details/${item._id}`}
                                    className="btn btn-indigo"
                                  >
                                    details
                                  </Link>
                                </td>
                                <td className="td">
                                  {item?.status ? (
                                    item?.received ? (
                                      <span className="capitalize font-medium text-emerald-600">
                                        received
                                      </span>
                                    ) : (
                                      <button
                                        className="btn btn-indigo"
                                        onClick={() => orderReceived(item._id)}
                                      >
                                        received?
                                      </button>
                                    )
                                  ) : (
                                    <span className="capitalize font-medium text-rose-600">
                                      under process
                                    </span>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    {/* <Pagination
                      page={parseInt(page)}
                      perPage={data.perPage}
                      count={data.count}
                      path={`orders`}
                      theme="light"
                    /> */}
                  </div>
                ) : (
                  <div className="col-xl-9 col-lg-10 col-md-12  bg-indigo-50 border border-indigo-100 rounded px-4 py-2.5 capitalize text-indigo-900 text-sm font-medium">
                    no orders
                  </div>
                ):null 
              }

{selected_tab == 2 && 
            <form onSubmit={handleSubmit} className="col-xl-9 col-lg-10 col-md-12 content flex flex-col gap-3">
            {errorMessages.length > 0 && (
                <div className="alert alert-danger">
                    <ul>
                        {errorMessages.map((error, index) => (
                            <li key={index}>{error}</li>
                        ))}
                    </ul>
                </div>
            )}
            {successMessage && <div className="alert alert-success">{successMessage}</div>}
            <div className="form-group">
                <textarea className="form-control" name="addressLine1" value={formaddressData.addressLine1} onChange={handleaddressInputChange} rows="5" placeholder="Complete Address *" required></textarea>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <div className="form-group">
                        <select className="form-control" name="country" value={formaddressData.country} onChange={handleaddressInputChange} required>
                            <option value="India">India</option>
                        </select>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group">
                        <input type="text" className="form-control" name="state" value={formaddressData.state} onChange={handleaddressInputChange} placeholder="State *" required />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <div className="form-group">
                        <input type="text" className="form-control" name="city" value={formaddressData.city} onChange={handleaddressInputChange} placeholder="City *" required />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group">
                        <input type="text" className="form-control" name="postalCode" value={formaddressData.postalCode} onChange={handleaddressInputChange} placeholder="Pincode *" required />
                    </div>
                </div>
            </div>
            <div className="form-group">
                            <button type="submit" className="w-52 h-10 bg-primeColor text-white hover:bg-black duration-300" disabled={isAddingAddress || isUpdatingAddress}>
                                {isAddingAddress || isUpdatingAddress ? 'Processing...' : 'Submit'}
                            </button>
                        </div>
        </form>

// </div>
}




              {selected_tab == 3 &&
              <>
               <div className="col-xl-9 col-lg-10 col-md-12 content">
               {errorMessages.length > 0 && (
                <div className="alert alert-danger">
                    <ul>
                        {errorMessages.map((error, index) => (
                            <li key={index}>{error}</li>
                        ))}
                    </ul>
                </div>
            )}
               {successMessage && <div className="alert alert-success">{successMessage}</div>}
            <div className="row">
                <div className="col-md-6">
                    <div className="form-floating my-3">
                        <input type="text" className="form-control" id="checkout_first_name" name="name" value={userData.name} onChange={handleInputChange} placeholder="First Name" />
                        <label htmlFor="checkout_first_name">First Name</label>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-floating my-3">
                        <input type="email" className="form-control" id="checkout_last_name" name="email" value={userData.email} onChange={handleInputChange} placeholder="E-Mail" />
                        <label htmlFor="checkout_last_name">E-Mail</label>
                    </div>
                </div>
                <div className="col-md-12 mt-3">
                    <button className="w-52 h-10 bg-primeColor text-white hover:bg-black duration-300" onClick={handleUpdateProfile} disabled={isUpdatingProfile}>
                        {isUpdatingProfile ? 'Updating Profile...' : 'Update Profile'}
                    </button>
                </div>
                <div className="col-md-12">
                    <div className="form-floating mt-3 mb-3">
                        <input type="password" className="form-control" name="currentPassword" value={formData.currentPassword} onChange={handleInputChange} placeholder="Current Password" />
                        <label htmlFor="currentPassword">Current Password*</label>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-floating mt-3 mb-3">
                        <input type="password" className="form-control" name="newPassword" value={formData.newPassword} onChange={handleInputChange} placeholder="New Password" />
                        <label htmlFor="newPassword">New Password *</label>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-floating mt-3 mb-3">
                        <input type="password" className="form-control" name="confirmNewPassword" value={formData.confirmNewPassword} onChange={handleInputChange} placeholder="Confirm New Password" />
                        <label htmlFor="confirmNewPassword">Confirm New Password *</label>
                    </div>
                </div>
                <div className="col-md-12">
                    <button className="w-52 h-10 bg-primeColor text-white hover:bg-black duration-300" onClick={handleChangePassword} disabled={isChangingPassword}>
                        {isChangingPassword ? 'Changing Password...' : 'Change Password'}
                    </button>
                </div>
                
            </div>
              </div>
              </>
              
              }




                    </div>
				</div>
			</div>


   </div>
    </>
  );
};

export default Profile;
