import React, { useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import {
  bannerImgOne,
  bannerImgTwo,
  bannerImgThree,
} from "../../assets/images";
import Image from "../designLayouts/Image";
import { useGetAllActiveBannerImagesForUserQuery } from "../../store/services/bannerImageService";
import SamplePrevArrow from "../home/NewArrivals/SamplePrevArrow";
import SampleNextArrow from "../home/NewArrivals/SampleNextArrow";

const Banner = () => {
  const { data, isFetching } = useGetAllActiveBannerImagesForUserQuery();
  const [dotActive, setDocActive] = useState(0);
  // const CustomPrevArrow = (props) => {
  //   const { className, style, onClick } = props;
  //   return (
  //     <div
  //       className={className}
  //       style={{
  //         ...style,
  //         display: "block",
  //         left: "10px",
  //         zIndex: 10,
  //         color: "black", // Example: Change arrow color
  //         fontSize: "30px", // Example: Change arrow size
  //         cursor: "pointer", // Example: Add cursor pointer,
  //         position:"absolute",top:"40%",backgroundColor:"rgba(255,255,255,0.5)",padding:'10px'
  //       }}
  //       onClick={onClick}
  //     >
  //       {"<"} {/* Example: Simple left arrow */}
  //     </div>
  //   );
  // };
  
  // const CustomNextArrow = (props) => {
  //   const { className, style, onClick } = props;
  //   return (
  //     <div
  //       className={className}
  //       style={{
  //         ...style,
  //         display: "block",
  //         right: "10px",
  //         zIndex: 10,
  //         color: "black", // Example: Change arrow color
  //         fontSize: "30px", // Example: Change arrow size
  //         cursor: "pointer", // Example: Add cursor pointer
  //         top: "40%",
  //         right: "10px",position:"absolute",backgroundColor:"rgba(255,255,255,0.5)",padding:'10px'
  //       }}
  //       onClick={onClick}
  //     >
  //       {">"} {/* Example: Simple right arrow */}
  //     </div>
  //   );
  // };
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    speed: 500,
    slidesToScroll: 1,
    arrows: true,
    fade: true, 
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    beforeChange: (prev, next) => {
      setDocActive(next);
    },
    appendDots: (dots) => (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "7%",
          transform: "translateY(-50%)",
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={
          i === dotActive
            ? {
              width: "30px",
              color: "#262626",
              borderRight: "3px #262626 solid",
              padding: "8px 0",
              cursor: "pointer",
            }
            : {
              width: "30px",
              color: "transparent",
              borderRight: "3px white solid",
              padding: "8px 0",
              cursor: "pointer",
            }
        }
      >
        0{i + 1}
      </div>
    ),
    responsive: [
      {
        breakpoint: 576,
        settings: {
          dots: true,
          appendDots: (dots) => (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "2%",
                transform: "translateY(-50%)",
              }}
            >
              <ul style={{ margin: "0px" }}> {dots} </ul>
            </div>
          ),
          customPaging: (i) => (
            <div
              style={
                i === dotActive
                  ? {
                    width: "25px",
                    color: "#262626",
                    borderRight: "3px #262626 solid",
                    cursor: "pointer",
                    fontSize: "12px",
                  }
                  : {
                    width: "25px",
                    color: "transparent",
                    borderRight: "3px white solid",
                    cursor: "pointer",
                    fontSize: "12px",
                  }
              }
            >
              0{i + 1}
            </div>
          ),
        },
      },
    ],
  };
  return (
    <div className="w-full bg-white">
      <Slider {...settings}>
        {!isFetching && data?.map((item, index) => (
          <Link key={index} to={item?.url}>
            <div className="relative h-[126px] md:h-[315px] w-full">
              <Image 
                className="w-full h-full object-cover" 
                imgSrc={item?.image} 
                alt={`Banner ${index + 1}`}
              />
            </div>
          </Link>
        ))}
      </Slider>
    </div>
  );
};

export default Banner;
