import React from "react";
import { Link, useLocation } from "react-router-dom";
import Image from "../../designLayouts/Image";
import { bulk, corporate } from "../../../assets/images";

const Sale = () => {
  const location = useLocation();
  return (
    <div className="py-2 flex flex-col md:flex-row items-center justify-between gap-4 lg:gap-10">
      <div className="w-full h-full flex flex-col md:flex-row gap-4 lg:gap-10">
        <div className="w-full">
          <Link to="/contact" state={{ data: location.pathname.split("/")[1] }}>
            <Image className="h-full w-full object-cover" imgSrc={'https://mikirei.com/uploads/8e2f6d537f5ec0e06090f48ce8052a827d2c3b0e.jpg'} />
          </Link>
        </div>
        <div className="w-full">
          <Link to="/contact" state={{ data: location.pathname.split("/")[1] }}>
            <Image className="h-full w-full object-cover" imgSrc={'https://mikirei.com/uploads/6aaabb66ad5a9d7380f3cfbbfd28e1dcfbc18fde.jpg'} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Sale;
