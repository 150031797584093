import { Virtual, Navigation, Autoplay } from "swiper";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { useAllCategoriesQuery, useNonAccessoryCategoriesQuery } from "../../store/services/categoryService";
import 'swiper/swiper-bundle.min.css';

const Categories = () => {
  const { data, isFetching } = useNonAccessoryCategoriesQuery();

  if (isFetching) {
    return <div>Loading...</div>;
  }

  return (
    data?.categories.length > 0 && (
      <Swiper
        modules={[Virtual, Navigation, Autoplay]}
        spaceBetween={20}
        slidesPerView={10}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        virtual
        className="w-full h-[160px] md:h-[250px] categories-swiper"
        breakpoints={{
          0: {
            slidesPerView: 2,
          },
          640: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 4,
          },
          1080: {
            slidesPerView: 5,
          },
          1280: {
            slidesPerView: 6,
          },
        }}
      >
        {data.categories.map((category, index) => (
          <SwiperSlide
            className="w-full overflow-hidden relative soccer "
            key={index}
            virtualIndex={index}
          >
            <Link
              to={`/shop/${category.name}`}
              className="text-black text-base font-medium capitalize w-full flex flex-col items-center"
            >
              <div className="w-[100px] h-[100px] md:w-[150px] md:h-[150px] rounded-full overflow-hidden supersm">
                <img
                  src={category.image ? category.image : "images/logo/2.png"}
                  className="w-full h-full object-cover"
                  alt={category.name}
                />
              </div>
              <div className="mt-1 text-xs text-center">
                {category.name}
              </div>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    )
  );
};

export default Categories;
