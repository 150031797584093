import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const homeProducts = createApi({
  reducerPath: "homeProducts",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://thebookwale.com/api/api/",
  }),
  endpoints: (builder) => {
    return {
      catProducts: builder.query({
        query: ({ name, page = 1, boards, classes, categories, shoesGender, uniformType }) => {
          return {
            url: `cat-products/${name}/${page}`,
            method: 'POST',
            body: {
              boards,
              classes,
              categories,
              shoesGender,
              uniformType
            }
          };
        },
      }),
      brandProducts: builder.query({
        query: ({ name, page = 1, keyword, type, producttype, class: classParam, boards, classes, categories, shoesGender, uniformType }) => {
          // Initialize URLSearchParams
          const params = new URLSearchParams();
      
          // Conditionally add query parameters if they are defined
          if (keyword) params.append('keyword', keyword);
          if (type) params.append('type', type);
          if (classParam) params.append('class', classParam);
          if (producttype && producttype !== 'undefined') params.append('producttype', producttype);

          // Construct the final URL with the query string
          const url = `brand-products/${name}/${page}?${params.toString()}`;
      
          return {
            url,
            method: 'POST',
            body: {
              boards,
              classes,
              categories,
              shoesGender,
              uniformType
            }
          };
        },
      }),
      
      searchProducts: builder.query({
        query: (params) => {
          return {
            url: `search-products/${params.keyword}/${params.page}`,
            method: "GET",
          };
        },
      }),
    };
  },
});
export const { useCatProductsQuery,useBrandProductsQuery, useSearchProductsQuery } = homeProducts;
export default homeProducts;
