import React, { useState } from "react";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import Pagination from "../../components/pageProps/shopPage/Pagination";
import ProductBanner from "../../components/pageProps/shopPage/ProductBanner";
import ShopSideNav from "../../components/pageProps/shopPage/ShopSideNav";
import { useParams } from "react-router-dom";
import { useBrandProductsQuery } from "../../store/services/homeProducts";
import ProductLoader from "../../components/ProductLoader/ProductLoader";

const ShopByClass = () => {
  const { name, page = 1, slug,producttype } = useParams();


  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title={"Products For " + name} />
      {/* ================= Products Start here =================== */}
      <div className="w-full h-full flex pb-20 gap-10">
        {/* <div className="w-[20%] lgl:w-[25%] hidden mdl:inline-flex h-full">
          <ShopSideNav />
        </div>
        <div className="w-full mdl:w-[80%] lgl:w-[75%] h-full flex flex-col gap-10">
        
        </div> */}

        <div className="cardrow">
          <div className="large-12 nasa-col columns">
            <div className="row row-inner row-fluid grade-center-align vc_column-gap-30">
              <div className="large-1 nasa-col columns">
                <div className="vc_empty_space" style={{ height: "32px" }}>
                  <span className="vc_empty_space_inner"></span>
                </div>
              </div>

              <div className="col-md-4">
                <div
                  className="grade-section large-3 nasa-col columns"
                  style={{ width: "100%" }}
                >
                  <div className="vc_btn3-container  grade-bottun vc_btn3-center vc_do_btn">
                    <button className="vc_general vc_btn3 vc_btn3-size-lg vc_btn3-shape-rounded vc_btn3-style-modern vc_btn3-color-success">
                      Nursery &amp; KG
                    </button>
                  </div>
                  <div className="wpb_single_image wpb_content_element vc_align_center wpb_content_element vc_custom_1710167813127">
                    <figure className="wpb_wrapper vc_figure">
                      <a
                        
                        href={`/school_shop/${name}/${slug}/nursery_kg/summer/${producttype}`}
                        target="_self"
                        className="vc_single_image-wrapper   vc_box_border_grey"
                      >
                        <img
                          fetchpriority="high"
                          decoding="async"
                          width="500"
                          height="334"
                          src="https://sethistores.com/wp-content/uploads/2.png"
                          className="vc_single_image-img attachment-full"
                          alt=""
                          title="2"
                          srcset="https://sethistores.com/wp-content/uploads/2.png 500w, https://sethistores.com/wp-content/uploads/2-450x301.png 450w"
                          sizes="(max-width: 500px) 100vw, 500px"
                        />
                      </a>
                    </figure>
                  </div>
                  <div className="vc_empty_space" style={{ height: "10px" }}>
                    <span className="vc_empty_space_inner"></span>
                  </div>
                  <div className="wpb_single_image wpb_content_element vc_align_center wpb_content_element">
                    <figure className="wpb_wrapper vc_figure">
                      <a
                        
                        href={`/school_shop/${name}/${slug}/nursery_kg/winter/${producttype}`}
                        target="_self"
                        className="vc_single_image-wrapper   vc_box_border_grey"
                      >
                        <img
                          decoding="async"
                          width="500"
                          height="334"
                          src="https://sethistores.com/wp-content/uploads/1-2.png"
                          className="vc_single_image-img attachment-full"
                          alt=""
                          title="1"
                          srcset="https://sethistores.com/wp-content/uploads/1-2.png 500w, https://sethistores.com/wp-content/uploads/1-2-450x301.png 450w"
                          sizes="(max-width: 500px) 100vw, 500px"
                        />
                      </a>
                    </figure>
                  </div>
                  <div className="vc_empty_space" style={{ height: "10px" }}>
                    <span className="vc_empty_space_inner"></span>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="grade-section-2 large-3 nasa-col columns"
                  style={{ width: "100%" }}
                >
                  <div className="vc_btn3-container  grade-bottun vc_btn3-center vc_do_btn">
                    <button className="vc_general vc_btn3 vc_btn3-size-lg vc_btn3-shape-rounded vc_btn3-style-modern vc_btn3-color-violet">
                      Grade 1 &amp; 2
                    </button>
                  </div>
                  <div className="wpb_single_image wpb_content_element vc_align_center wpb_content_element vc_custom_1710167926557">
                    <figure className="wpb_wrapper vc_figure">
                      <a
                         href={`/school_shop/${name}/${slug}/grade_one_two/summer/${producttype}`}
                        target="_self"
                        className="vc_single_image-wrapper   vc_box_border_grey"
                      >
                        <img
                          fetchpriority="high"
                          decoding="async"
                          width="500"
                          height="334"
                          src="https://sethistores.com/wp-content/uploads/2.png"
                          className="vc_single_image-img attachment-full"
                          alt=""
                          title="2"
                          srcset="https://sethistores.com/wp-content/uploads/2.png 500w, https://sethistores.com/wp-content/uploads/2-450x301.png 450w"
                          sizes="(max-width: 500px) 100vw, 500px"
                        />
                      </a>
                    </figure>
                  </div>
                  <div className="vc_empty_space" style={{ height: "10px" }}>
                    <span className="vc_empty_space_inner"></span>
                  </div>
                  <div className="wpb_single_image wpb_content_element vc_align_center wpb_content_element">
                    <figure className="wpb_wrapper vc_figure">
                      <a
                         href={`/school_shop/${name}/${slug}/grade_one_two/winter/${producttype}`}
                        target="_self"
                        className="vc_single_image-wrapper   vc_box_border_grey"
                      >
                        <img
                          decoding="async"
                          width="500"
                          height="334"
                          src="https://sethistores.com/wp-content/uploads/1-2.png"
                          className="vc_single_image-img attachment-full"
                          alt=""
                          title="1"
                          srcset="https://sethistores.com/wp-content/uploads/1-2.png 500w, https://sethistores.com/wp-content/uploads/1-2-450x301.png 450w"
                          sizes="(max-width: 500px) 100vw, 500px"
                        />
                      </a>
                    </figure>
                  </div>
                  <div className="vc_empty_space" style={{ height: "10px" }}>
                    <span className="vc_empty_space_inner"></span>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="grade-section-3 large-3 nasa-col columns"
                  style={{ width: "100%" }}
                >
                  <div className="vc_btn3-container  grade-bottun vc_btn3-center vc_do_btn">
                    <button className="vc_general vc_btn3 vc_btn3-size-lg vc_btn3-shape-rounded vc_btn3-style-modern vc_btn3-color-pink">
                      Grade 3 to 12
                    </button>
                  </div>
                  <div className="wpb_single_image wpb_content_element vc_align_center wpb_content_element vc_custom_1710167900266">
                    <figure className="wpb_wrapper vc_figure">
                      <a
                       href={`/school_shop/${name}/${slug}/grade_three_twelve/summer/${producttype}`}
                        target="_self"
                        className="vc_single_image-wrapper   vc_box_border_grey"
                      >
                        <img
                          fetchpriority="high"
                          decoding="async"
                          width="500"
                          height="334"
                          src="https://sethistores.com/wp-content/uploads/2.png"
                          className="vc_single_image-img attachment-full"
                          alt=""
                          title="2"
                          srcset="https://sethistores.com/wp-content/uploads/2.png 500w, https://sethistores.com/wp-content/uploads/2-450x301.png 450w"
                          sizes="(max-width: 500px) 100vw, 500px"
                        />
                      </a>
                    </figure>
                  </div>
                  <div className="vc_empty_space" style={{ height: "10px" }}>
                    <span className="vc_empty_space_inner"></span>
                  </div>
                  <div className="wpb_single_image wpb_content_element vc_align_center wpb_content_element">
                    <figure className="wpb_wrapper vc_figure">
                      <a
                        href={`/school_shop/${name}/${slug}/grade_three_twelve/winter/${producttype}`}
                        target="_self"
                        className="vc_single_image-wrapper   vc_box_border_grey"
                      >
                        <img
                          decoding="async"
                          width="500"
                          height="334"
                          src="https://sethistores.com/wp-content/uploads/1-2.png"
                          className="vc_single_image-img attachment-full"
                          alt=""
                          title="1"
                          srcset="https://sethistores.com/wp-content/uploads/1-2.png 500w, https://sethistores.com/wp-content/uploads/1-2-450x301.png 450w"
                          sizes="(max-width: 500px) 100vw, 500px"
                        />
                      </a>
                    </figure>
                  </div>
                  <div className="vc_empty_space" style={{ height: "10px" }}>
                    <span className="vc_empty_space_inner"></span>
                  </div>
                </div>
              </div>
            </div>
            <div className="large-1 nasa-col columns">
              <div className="vc_empty_space" style={{ height: "32px" }}>
                <span className="vc_empty_space_inner"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ================= Products End here ===================== */}
    </div>
  );
};

export default ShopByClass;
