import { Link } from "react-router-dom";

const BrandCard = ({ brand,producttype }) => {
  const { slug, name, image } = brand;
  return (
    <Link
    to={`/school_shop/${name}/${slug}`}
    >
      <div className="group flex flex-col items-center text-center relative overflow-hidden rounded-md schoolsoccer">
        <div className="brandcard p-1 w-20 h-20 sm:w-24 sm:h-24 md:w-36 md:h-36 lg:w-48 lg:h-48">
          <img
            src={`${image?.original}` ?? "/assets/placeholder/brand-bg.svg"}
            alt={name || 'product'}
            className="rounded-md object-cover transform transition-transform ease-in-out duration-500 group-hover:rotate-6 group-hover:scale-125"
          />
        </div>
        <div className="mt-2 px-4 text-xs sm:text-xs md:text-sm">
          <span className="text-black font-bold">{name}</span>
        </div>
      </div>
    </Link>
  );
};

export default BrandCard;
