import React, { useState } from "react";
import SpecialOffers from "../../components/home/SpecialOffers/SpecialOffers";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import { useGetBrandsQuery } from "../../store/services/brandService";
import BrandCard from "../../components/home/BrandCard/BrandCard";
import { Link, useParams } from "react-router-dom";

const Offer = () => {
  const [prevLocation] = useState("");
  const {producttype} = useParams()
  const { data: brandsdata, isLoading, error } = useGetBrandsQuery();
  const brands = brandsdata?.brands;

  return (
    <div className="max-w-container mx-auto">
      <Breadcrumbs title="Schools" prevLocation={prevLocation} />
      <div className="pb-10">
      
          <div className="grid grid-cols-2 sm:grid-cols-2 2xl:grid-cols-6 gap-2.5 md:gap-3 lg:gap-5 xl:gap-7">
            {!isLoading &&
              brands.map((brand) => (
                <BrandCard key={`brand--key${brand.id}`} brand={brand} producttype={producttype}/>
              ))}
          </div>
      </div>
    </div>
  );
};

export default Offer;
