import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { addToCart } from "../../../store/orebiSlice";
import { BsCheck2 } from "react-icons/bs";
import toast from "react-hot-toast";
import html2plaintext from "html2plaintext";
import HTMLReactParser from "html-react-parser";
import currency from "currency-formatter";
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import { addCart } from "../../../store/reducers/cartReducer";
import { discount } from "../../../utils/discount";
import { useNavigate } from "react-router-dom";
import { FaShippingFast } from "react-icons/fa";
import { MdOutlineCurrencyExchange } from "react-icons/md";

const ProductInfo = ({ productInfo }) => {
  const dispatch = useDispatch();
  const [sizeState, setSizeState] = useState(
    productInfo?.sizes?.length > 0 ? productInfo.sizes[0] : null
  );
  const [colorState, setColorState] = useState(
    productInfo?.colors?.length > 0 ? productInfo.colors[0].color : ""
  );
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(1);

  let desc = HTMLReactParser(productInfo?.description);

  useEffect(() => {
    if (productInfo?.sizes?.length > 0) {
      setSizeState(productInfo.sizes[0]);
    }
  }, [productInfo]);

  const addToCart_apihit = () => {
    const {
      ["colors"]: colors,
      ["sizes"]: sizes,
      ["createdAt"]: createdAt,
      ["updatedAt"]: updatedAt,
      ...newProduct
    } = productInfo;
    newProduct["size"] = sizeState.name;
    newProduct["color"] = colorState;
    newProduct["quantity"] = quantity;
    newProduct["price"] = sizeState.price; // Use selected size price
    newProduct["shipping"] = productInfo.shipping;
    const cart = localStorage.getItem("cart");
    const cartItems = cart ? JSON.parse(cart) : [];
    const checkItem = cartItems.find((item) => item._id === newProduct._id && item.size === newProduct.size);
    if (!checkItem) {
      dispatch(addCart(newProduct));
      cartItems.push(newProduct);
      toast.success(`${newProduct.title} Added in cart`);
      localStorage.setItem("cart", JSON.stringify(cartItems));
    } else {
      toast.error(`${newProduct.title} is already in cart`);
    }
  };

  const buynow_apihit = () => {
    const {
      ["colors"]: colors,
      ["sizes"]: sizes,
      ["createdAt"]: createdAt,
      ["updatedAt"]: updatedAt,
      ...newProduct
    } = productInfo;
    newProduct["size"] = sizeState.name;
    newProduct["color"] = colorState;
    newProduct["quantity"] = quantity;
    newProduct["price"] = sizeState.price; // Use selected size price
    newProduct["shipping"] = productInfo.shipping;
    const cart = localStorage.getItem("cart");
    const cartItems = cart ? JSON.parse(cart) : [];
    const checkItem = cartItems.find((item) => item._id === newProduct._id && item.size === newProduct.size);
    if (!checkItem) {
      dispatch(addCart(newProduct));
      cartItems.push(newProduct);
      toast.success(`Processing to Payment`);
      localStorage.setItem("cart", JSON.stringify(cartItems));
      navigate("/paymentgateway");
    } else {
      toast.error(`${newProduct.title} is already in cart`);
    }
  };

  return (
    <div className="w-full order-1 md:order-2 flex flex-col ">
      <h3 className="text-xl text-gray-900 capitalize fontstyleclass">
        {productInfo.title}
      </h3>
      <div className="flex justify-start items-center my-1">
        {sizeState && sizeState.price && (
          <>
            <span className="text-xl line-through fontstyleclass text-red-500 mr-5">
              {currency.format(sizeState.price, { code: "INR" })}
            </span>
            <span className="text-2xl fontstyleclass text-gray-900">
              {currency.format(discount(sizeState.price, productInfo.discount), {
                code: "INR",
              })}
            </span>
          </>
        )}
      </div>

      {productInfo?.sizes?.length > 0 && (
        <>
          <span className="text-xl fontstyleclass">Sizes:</span>
          <div className="flex flex-wrap -mx-1">
            {productInfo.sizes.map((size) => (
              <div
                className={`p-2 m-1 border border-gray-300 rounded cursor-pointer ${
                  sizeState?.name === size.name && "bg-indigo-600"
                }`}
                key={size.name}
                onClick={() => setSizeState(size)}
              >
                <span
                  className={`text-sm font-semibold uppercase ${
                    sizeState?.name === size.name ? "text-white" : "text-gray-900"
                  }`}
                >
                  {size.name}
                </span>
              </div>
            ))}
          </div>
        </>
      )}
      <div className="d-flex flex-row justify-content-between align-items-center p-2 mt-4 px-3 rounded">
        <div className="d-flex flex-row align-self-center product_data" id="qty_select">
          <input type="hidden" value="1" className="prod_id" />
          <div
            className="input-group text-center"
            style={{ alignItems: "center", paddingLeft: "5px" }}
            id="qty_selector"
          >
            <a
              className="decrement-btn"
              onClick={() => quantity != 1 && setQuantity(quantity - 1)}
            >
              <FaMinus />
            </a>
            <input
              type="text"
              readOnly="readonly"
              id="qty_display"
              className="qty-input text-center"
              value={quantity}
            />
            <a className="increment-btn" onClick={() => setQuantity(quantity + 1)}>
              <FaPlus />
            </a>
          </div>
        </div>
      </div>

      <p className="text-l text-gray-600 fontstyleclass my-3">{desc}</p>

      <p className="text-sm">Be the first to leave a review.</p>

      <p className="text-sm text-gray-600 fontstyleclass">
        <div className="d-flex align-items-center">
          <MdOutlineCurrencyExchange
            size={20}
            style={{ marginRight: "10px" }}
          />
          {productInfo?.shipping == 0
            ? "FREE DELIVERY"
            : `Shipping Charges - ${currency.format(productInfo?.shipping, {
                code: "INR",
              })}`}
        </div>
      </p>

      <div className="checkoutbutton-container align-self-center w-full mb-2">
        <button
          onClick={addToCart_apihit}
          className="checkoutbutton w-full py-2 bg-blueColor hover:bg-black duration-300 text-white text-lg font-titleFont"
          style={{ borderRadius: "20px" }}
        >
          Add to Cart
        </button>
        <button
          onClick={buynow_apihit}
          className="buynowbutton w-full py-2 bg-orangefade hover:bg-black duration-300 text-white text-lg font-titleFont"
          style={{ borderRadius: "20px" }}
        >
          Buy Now
        </button>
      </div>
      {productInfo?.shipdetails && (
        <p className="text-l text-gray-600 fontstyleclass my-3">
          <div className="d-flex align-items-center">
            <FaShippingFast size={30} style={{ marginRight: "10px" }} />
            {HTMLReactParser(productInfo?.shipdetails)}
          </div>
        </p>
      )}

      <p className="font-normal text-sm">
        <span className="text-base font-medium">Categories:</span>{" "}
        {productInfo?.category}, {productInfo?.brandName}
      </p>
    </div>
  );
};

export default ProductInfo;
