import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import ScreenHeader from "../../components/ScreenHeader";
import Wrapper from "./Wrapper";
import { useCreateMutation } from "../../store/services/categoryService";
import { setSuccess } from "../../store/reducers/globalReducer";
import ImagesPreview from "../../components/ImagesPreview"; // Import the ImagesPreview component

const CreateCategory = () => {
    const [name, setName] = useState('');
    const [image, setImage] = useState(null); // Add image state
    const [imagePreview, setImagePreview] = useState(null); // Add image preview state
    const [accessory, setAccessory] = useState(false);
    const [saveCategory, data] = useCreateMutation();
    const errors = data?.error?.data?.errors ? data?.error?.data?.errors : [];
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    useEffect(() => {
        if (data?.isSuccess) {
            dispatch(setSuccess(data?.data?.message));
            navigate('/dashboard/categories');
        }
    }, [data?.isSuccess]);
    
    const submitCategory = (e) => {
        e.preventDefault();
        
        // Create FormData object to send name and image file
        const formData = new FormData();
        formData.append('name', name);
        formData.append('image', image);
        formData.append('accessory', accessory);
        
        saveCategory(formData);
    };
    
    const handleImageUpload = (e) => {
        const selectedImage = e.target.files[0];
        setImage(selectedImage);
        
        // Preview image
        const reader = new FileReader();
        reader.onloadend = () => {
            setImagePreview(reader.result);
        };
        reader.readAsDataURL(selectedImage);
    };
    
    return (
        <Wrapper>
            <ScreenHeader>
                <Link to="/dashboard/categories" className="btn-dark">
                    <i className="bi bi-arrow-left-short"></i> categories list
                </Link>
            </ScreenHeader>
            <form className="w-full md:w-8/12" onSubmit={submitCategory}>
                <h3 className="text-lg capitalize mb-3">create category</h3>
                {errors.length > 0 && errors.map((error, key) => (
                    <p className="alert-danger" key={key}>{error.msg}</p>
                ))}
                <div className="mb-3">
                    <input type="text" name="" className="form-control" placeholder="Category Name..." value={name} onChange={(e) => setName(e.target.value)} />
                </div>
                <div className="mb-3">
                    {/* Input field for image upload */}
                    <input type="file" accept="image/*" onChange={handleImageUpload} />
                </div>
                {/* Image preview */}
                <div className="w-full xl:w-2/12 p-3">
                {imagePreview && <ImagesPreview url={imagePreview} heading="Image Preview" />}</div>
                <div className="mb-3">
                    {/* Checkbox for Accessory */}
                    <label className="inline-flex items-center">
                        <input
                            type="checkbox"
                            className="form-checkbox"
                            checked={accessory}
                            onChange={(e) => setAccessory(e.target.checked)}
                        />
                        <span className="ml-2">Is Accessory?</span>
                    </label>
                </div>

                <div className="mb-3">
                    <input type="submit" value={data.isLoading ? 'loading...' : 'create category'} className="btn-indigo" />
                </div>
            </form>
          
        </Wrapper>
    );
};

export default CreateCategory;
