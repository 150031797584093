import { Link } from "react-router-dom";
import { useAccessoryCategoriesQuery } from "../../store/services/categoryService";

const AccessoriesCategories = () => {
  const { data, isFetching } = useAccessoryCategoriesQuery();

  if (isFetching) {
    return <div>Loading...</div>;
  }

  return (
    data?.categories.length > 0 && (
      <div className="categories-grid grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 gap-8 p-6">
        {data.categories.map((category, index) => (
          <Link
            to={`/shop/${category.name}`}
            className="category-item text-black text-base font-medium capitalize flex flex-col items-center border-6 border-blue-600 rounded-lg overflow-hidden"
            key={index}
          >
            <div className="w-[150px] h-[150px] md:w-[200px] md:h-[200px] rounded-full overflow-hidden">
              <img
                src={category.image ? category.image : "images/logo/2.png"}
                className="w-full h-full object-cover"
                alt={category.name}
              />
            </div>
            <div className="mt-2 text-sm text-center">
              {category.name}
            </div>
          </Link>
        ))}
      </div>
    )
  );
};

export default AccessoriesCategories;
