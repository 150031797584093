import { Link } from "react-router-dom";
import { FaShop } from "react-icons/fa6";

import { FaCartShopping } from "react-icons/fa6";
import { IoPersonCircle } from "react-icons/io5";
import { BiSolidCategoryAlt } from "react-icons/bi";
import { FaSchool } from "react-icons/fa6";
import { IoIosContacts } from "react-icons/io";

const Sidebar = ({ side, closeSidebar }) => {
  return (
    <div
      className={`fixed top-0 ${side} sm:left-0 w-64 h-screen bg-gray-800 z-10 transition-all`}
    >
      <i
        className="bi bi-x-lg absolute top-4 right-4 sm:hidden block cursor-pointer text-lg"
        onClick={closeSidebar}
      ></i>
      <div className=" p-1 w-[150px] h-[90px]">
        <img src="/images/logo/1.png" alt="logo" />
      </div>
      <ul className="mt-1">
        <li className="px-4 cursor-pointer transition-all py-3 text-white flex items-center hover:bg-gray-600">
        <FaShop size={32}/>
          <Link to="/dashboard/sliders" className="text-base capitalize ml-10">
            Slider
          </Link>
        </li>
        <li className="px-4 cursor-pointer transition-all py-3 text-white flex items-center hover:bg-gray-600">
        <FaShop size={32}/>
          <Link to="/dashboard/logos" className="text-base capitalize ml-10">
            Logo
          </Link>
        </li>
        <li className="px-4 cursor-pointer transition-all py-3 text-white flex items-center hover:bg-gray-600">
        <FaShop size={32}/>
          <Link to="/dashboard/products" className="text-base capitalize ml-10">
            products
          </Link>
        </li>
        <li className="px-4 cursor-pointer transition-all py-3 text-white flex items-center hover:bg-gray-600">
        <FaCartShopping size={32}/>
          <Link to="/dashboard/orders" className="text-base capitalize ml-10">
            orders
          </Link>
        </li>
        <li className="px-4 cursor-pointer transition-all py-3 text-white flex items-center hover:bg-gray-600">
        <IoPersonCircle size={32}/>
          <Link to="/dashboard/all_users" className="text-base capitalize ml-10">
            customers
          </Link>
        </li>
        <li className="px-4 cursor-pointer transition-all py-3 text-white flex items-center hover:bg-gray-600">
          <BiSolidCategoryAlt size={32}/>
          <Link to="/dashboard/categories" className="text-base capitalize ml-10">
            categories
          </Link>
        </li>
        <li className="px-4 cursor-pointer transition-all py-3 text-white flex items-center hover:bg-gray-600">
        <FaSchool size={32}/>
          <Link to="/dashboard/brands" className="text-base capitalize ml-10">
            Schools
          </Link>
        </li>
        <li className="px-4 cursor-pointer transition-all py-3 text-white flex items-center hover:bg-gray-600">
        <FaSchool size={32}/>
          <Link to="/dashboard/subscribers" className="text-base capitalize ml-10">
          Subscribers List
          </Link>
        </li>
        <li className="px-4 cursor-pointer transition-all py-3 text-white flex items-center hover:bg-gray-600">
        <IoIosContacts  size={32}/>
          <Link to="/dashboard/requests" className="text-base capitalize ml-10">
          Contact Enquiry
          </Link>
        </li>
        <li className="px-4 cursor-pointer transition-all py-3 text-white flex items-center hover:bg-gray-600">
        <IoIosContacts  size={32}/>
          <Link to="/dashboard/contact-us" className="text-base capitalize ml-10">
          Contact Us
          </Link>
        </li>
        <li className="px-4 cursor-pointer transition-all py-3 text-white flex items-center hover:bg-gray-600">
        <IoIosContacts  size={32}/>
          <Link to="/dashboard/policypages" className="text-base capitalize ml-10">
          Policy Pages
          </Link>
        </li>
      </ul>
    </div>
  );
};
export default Sidebar;
