import React from "react";

const Badge = ({ text }) => {
  return (
    <div className="bg-primeColor w-[42px] h-[15px] text-white flex justify-center items-center text-base font-semibold hover:bg-black duration-300 cursor-pointer md:w-[92px] md:h-[35px] w-[40px] h-[20px] text-xs md:text-sm">
      {text}
    </div>
  );
};

export default Badge;
