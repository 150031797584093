import { Link, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import ScreenHeader from "../../components/ScreenHeader";
import Wrapper from "./Wrapper";
import { setSuccess } from "../../store/reducers/globalReducer";
import { useFetchCategoryQuery, useUpdateCategoryMutation } from "../../store/services/categoryService";
import Spinner from "../../components/Spinner";

const UpdateCategory = () => {
    const [name, setName] = useState('');
    const [image, setImage] = useState('');
    const [imagePreview, setImagePreview] = useState(null);
    const [accessory, setAccessory] = useState(false);
    const { id } = useParams();
    const { data, isFetching } = useFetchCategoryQuery(id);
    const [saveCategory, response] = useUpdateCategoryMutation();
    const errors = response?.error?.data?.errors ? response?.error?.data?.errors : [];
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (data?.category) {
            const { name, image,accessory } = data?.category;
            setName(name);
            setImage(image);
            setAccessory(accessory)
            setImagePreview(image)
        }
    }, [data?.category]);

    const updateSubmit = async (e) => {
        e.preventDefault();
    
        const formData = new FormData();
        formData.append('name', name);
        formData.append('image', image);
        formData.append('accessory', accessory);
        
        try {
            await saveCategory({ id:id, data: formData });
        } catch (error) {
            console.error('Error updating category:', error);
        }
    }

    useEffect(() => {
        if (response?.isSuccess) {
            dispatch(setSuccess(response?.data?.message));
            navigate('/dashboard/categories');
        }
    }, [response?.isSuccess]);


     
    const handleImageUpload = (e) => {
        const selectedImage = e.target.files[0];
        setImage(selectedImage);
        
        // Preview image
        const reader = new FileReader();
        reader.onloadend = () => {
            setImagePreview(reader.result);
        };
        reader.readAsDataURL(selectedImage);
    };

    return (
        <Wrapper>
            <ScreenHeader>
                <Link to="/dashboard/categories" className="btn-dark">
                    <i className="bi bi-arrow-left-short"></i> Categories List
                </Link>
            </ScreenHeader>
            {!isFetching ? (
                <form className="w-full md:w-8/12" onSubmit={updateSubmit}>
                    <h3 className="text-lg capitalize mb-3">Update Category</h3>
                    {errors.length > 0 && errors.map((error, key) => (
                        <p className="alert-danger" key={key}>{error.msg}</p>
                    ))}
                    <div className="mb-3">
                        <input
                            type="text"
                            name="name"
                            className="form-control"
                            placeholder="Category Name..."
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <img src={imagePreview} alt="Category" className="w-1/4 h-auto mb-3" />
                        {/* Add input for new image upload */}
                        <input
                            type="file"
                            accept="image/*"
                            name="image"
                            id="image"
                            className="input-file"
                            onChange={handleImageUpload}
                        />
                    </div>
                    <div className="mb-3">
                    {/* Checkbox for Accessory */}
                    <label className="inline-flex items-center">
                        <input
                            type="checkbox"
                            className="form-checkbox"
                            checked={accessory}
                            onChange={(e) => setAccessory(e.target.checked)}
                        />
                        <span className="ml-2">Is Accessory?</span>
                    </label>
                </div>

                    <div className="mb-3">
                        <input type="submit" value='Update' className="btn btn-indigo" />
                    </div>
                </form>
            ) : <Spinner />}
        </Wrapper>
    );
}

export default UpdateCategory;