import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import { useSelector } from "react-redux";
import { useGetContactUsByIdQuery } from "../../store/services/contactusService";
import { useGetSingleInformationQuery } from "../../store/services/informationService";
import HTMLReactParser from "html-react-parser";

const DynamicPage = () => {

  const { id } = useParams();
  const { data, isFetching } = useGetSingleInformationQuery(id);


  if(isFetching) {
    return(
        <>
        Loading....</>
    )
  }
  console.log(data,"our data")
  return (
    <div className="max-w-container mx-auto px-4">
      {/* <Breadcrumbs title="About" prevLocation={prevLocation} /> */}
      <div className="pb-10">
      <div className="full-row bg-dark overlay-dark py-5" style={{backgroundImage: 'url("https://johnfrancis.ca/en/wp-content/uploads/2021/08/1-1.jpg")', backgroundPosition: 'center center', backgroundSize: 'cover'}} id="fuma">
                <div className="container">
                    <div className="row text-center text-white">
                        <div className="col-12">
                            <h3 className="mb-2 text-white">{data[0]?.title}</h3>
                        </div>
                        <div className="col-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-inline-flex bg-transparent p-0">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{color:"white"}}>{data[0]?.title}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            <div className="full-row p-0" style={{backgroundSize: 'auto',backgroundPosition: 'right bottom'}}>
                <div className="container-fluid">
                  
                {data[0]?.details &&  <div>{HTMLReactParser(data[0]?.details)}</div>}
                </div>
            </div>
      </div>
    </div>
  );
};

export default DynamicPage;
