import React, { useState, useEffect } from 'react';
import { FaSearch, FaTimes } from 'react-icons/fa';
import { useNonAccessoryCategoriesQuery } from '../../../../store/services/categoryService';

const Filter = ({ onFilterChange }) => {
  const [priceRange, setPriceRange] = useState({ min: 0, max: 2000 });
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState({ boards: [], classes: [], categories: [] });
  const [expandedCategory, setExpandedCategory] = useState(null);
  const { data, isFetching } = useNonAccessoryCategoriesQuery();
  const [selectedFilters, setSelectedFilters] = useState({
    boards: [],
    classes: [],
    categories: [],
    priceRange: { min: 0, max: 2000 },
    shoesGender: '',
    uniformType: []
  });

  const boards = ['CBSE', 'ICSE'];
  const classes = ['KG', 'Nursery', 'L-KG', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

  useEffect(() => {
    const filtered = {
      boards: boards.filter(board => board.toLowerCase().includes(searchTerm.toLowerCase())),
      classes: classes.filter(cls => cls.toLowerCase().includes(searchTerm.toLowerCase())),
      categories: data?.categories?.filter(category => category.name.toLowerCase().includes(searchTerm.toLowerCase())) || []
    };
    setFilteredOptions(filtered);
  }, [searchTerm, data]);

  const handleFilterChange = (filterType, value) => {
    setSelectedFilters(prevFilters => ({
      ...prevFilters,
      [filterType]: Array.isArray(prevFilters[filterType])
        ? prevFilters[filterType].includes(value)
          ? prevFilters[filterType].filter(item => item !== value)
          : [...prevFilters[filterType], value]
        : value,
    }));
  };

  const renderCheckboxGroup = (title, options, filterType) => (
    <div className="mb-6">
      <h3 className="text-base font-semibold text-gray-700 mb-3">{title}</h3>
      <div className="space-y-3">
        {options.map((option) => (
          <div key={option.id || option}>
            <div className="flex items-center">
              <input
                type="checkbox"
                id={option.name || option}
                value={option.name || option}
                checked={selectedFilters[filterType].includes(option.name || option)}
                onChange={() => {
                  handleFilterChange(filterType, option.name || option);
                  if (option.name === 'Shoes' || option.name === 'Uniform') {
                    setExpandedCategory(expandedCategory === option.name ? null : option.name);
                  }
                }}
                className="form-checkbox h-5 w-5 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
              />
              <label htmlFor={option.name || option} className="ml-3 text-base text-gray-600">{option.name || option}</label>
            </div>
            {expandedCategory === 'Shoes' && option.name === 'Shoes' && (
              <div className="ml-6 mt-2 space-y-2">
                <div>
                  <input
                    type="radio"
                    id="shoes-male"
                    name="shoes-gender"
                    value="Male"
                    onChange={() => handleFilterChange('shoesGender', 'Male')}
                    className="mr-2"
                  />
                  <label htmlFor="shoes-male">Male</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="shoes-female"
                    name="shoes-gender"
                    value="Female"
                    onChange={() => handleFilterChange('shoesGender', 'Female')}
                    className="mr-2"
                  />
                  <label htmlFor="shoes-female">Female</label>
                </div>
              </div>
            )}
            {expandedCategory === 'Uniform' && option.name === 'Uniform' && (
              <div className="ml-6 mt-2 space-y-2">
                <div>
                  <input
                    type="checkbox"
                    id="uniform-male"
                    value="Male"
                    onChange={() => handleFilterChange('uniformType', 'Male')}
                    className="mr-2"
                  />
                  <label htmlFor="uniform-male">Male</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    id="uniform-female"
                    value="Female"
                    onChange={() => handleFilterChange('uniformType', 'Female')}
                    className="mr-2"
                  />
                  <label htmlFor="uniform-female">Female</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    id="uniform-summer"
                    value="Summer"
                    onChange={() => handleFilterChange('uniformType', 'Summer')}
                    className="mr-2"
                  />
                  <label htmlFor="uniform-summer">Summer</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    id="uniform-winter"
                    value="Winter"
                    onChange={() => handleFilterChange('uniformType', 'Winter')}
                    className="mr-2"
                  />
                  <label htmlFor="uniform-winter">Winter</label>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );

  const handlePriceChange = (e, type) => {
    setPriceRange(prev => ({ ...prev, [type]: parseInt(e.target.value) }));
    handleFilterChange('priceRange', { ...priceRange, [type]: parseInt(e.target.value) });
  };

  const handleApplyFilter = () => {
    onFilterChange(selectedFilters);
  };

  const clearSearch = () => {
    setSearchTerm('');
  };

  return (
    <div className="filter-container bg-white border border-gray-200 rounded-lg shadow-md p-6">
      <div className="mb-6 relative">
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full px-4 py-3 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        {searchTerm && (
          <button
            onClick={clearSearch}
            className="absolute right-10 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
          >
            <FaTimes className="text-sm" />
          </button>
        )}
        <FaSearch className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400 text-lg" />
      </div>
      
      {renderCheckboxGroup('Categories', filteredOptions.categories, 'categories')}
      {renderCheckboxGroup('Boards', filteredOptions.boards, 'boards')}
      {renderCheckboxGroup('Classes', filteredOptions.classes, 'classes')}
      
      <div className="mb-6">
        <h3 className="text-base font-semibold text-gray-700 mb-3">Price Range</h3>
        <div className="flex items-center space-x-4">
          <input 
            type="number" 
            value={priceRange.min} 
            onChange={(e) => handlePriceChange(e, 'min')}
            className="w-24 px-3 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <span className="text-base text-gray-500">to</span>
          <input 
            type="number" 
            value={priceRange.max} 
            onChange={(e) => handlePriceChange(e, 'max')}
            className="w-24 px-3 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
      </div>
      
      <button 
        onClick={handleApplyFilter}
        className="w-full bg-blue-600 text-white py-3 px-6 rounded-md hover:bg-blue-700 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 text-base font-medium"
      >
        Apply Filters
      </button>
    </div>
  );
};

export default Filter;
