import { NavLink, useNavigate } from "react-router-dom"
import {BsPersonCircle} from "react-icons/bs"
import {AiOutlineShoppingCart, AiOutlineLogout} from "react-icons/ai"
import {useDispatch} from "react-redux"
import { logout } from "../store/reducers/authReducer"
const AccountList = ({selected_tab}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
  return (
    <>
    <NavLink onClick={() => selected_tab(0)} className="account-list">
    <BsPersonCircle size={22} />
    <span className="account-list-title">my account</span>
    </NavLink>
    <NavLink onClick={() => selected_tab(1)} className="account-list">
    <AiOutlineShoppingCart size={22} />
    <span className="account-list-title">orders</span>
    </NavLink>
    <NavLink onClick={() => selected_tab(2)} className="account-list">
    <AiOutlineShoppingCart size={22} />
    <span className="account-list-title">Address</span>
    </NavLink>
    <NavLink onClick={() => selected_tab(3)} className="account-list">
    <AiOutlineShoppingCart size={22} />
    <span className="account-list-title">Profile</span>
    </NavLink>
    <span className="account-list cursor-pointer" onClick={() => {dispatch(logout('userToken'));navigate("/")}}>
    <AiOutlineLogout size={22} />
    <span className="account-list-title">logout</span>
    </span>
    </>
  )
}

export default AccountList