import React from "react";
import ReactDOM from "react-dom/client";
import { PersistGate } from "redux-persist/integration/react";
import "slick-carousel/slick/slick.css";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import Store, { persistor } from "./store";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from "react-router-dom";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={Store}>
     <BrowserRouter>
    {/* <PersistGate loading={null} persistor={persistor}> */}
      <App /></BrowserRouter>
    {/* </PersistGate> */}
  </Provider>
);
