import React from "react";
import { BsSuitHeartFill } from "react-icons/bs";
import { GiReturnArrow } from "react-icons/gi";
import { FaShoppingCart } from "react-icons/fa";
import { MdOutlineLabelImportant } from "react-icons/md";
import Image from "../../designLayouts/Image";
import Badge from "./Badge";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addToCart } from "../../../store/orebiSlice";
import currency from "currency-formatter";

const Product = (props) => {
  const dispatch = useDispatch();
  const _id = props._id;
  const rootId = _id;

  const navigate = useNavigate();
  const productItem = props;
  const handleProductDetails = () => {
    navigate(`/product/${rootId}`, {
      state: {
        item: productItem,
      },
    });
  };

  return (
    <div className="w-full relative groups" onClick={handleProductDetails}>
      <div className="relative overflow-hidden max-h-100 md:max-w-80 md:max-h-100 h-60 md:h-auto">
        <div className="duin">
          <Image className="w-full h-full product-mainimg" imgSrc={props.img} />
        </div>
        <div className="absolute top-5 right-2">
          {props.badge && <Badge text="New" />}
        </div>
        <div className="absolute bottom-0 left-0 w-full h-10 bg-white group-hover:bottom-0 duration-700">
          <ul className="w-full h-full flex flex-col items-end justify-center gap-2 font-titleFont px-2 border-l border-r">
            <li
              onClick={handleProductDetails}
              className="text-[#767676] hover:text-primeColor text-sm font-normal border-b-[1px] border-b-gray-200 hover:border-b-primeColor flex items-center justify-end gap-2 hover:cursor-pointer pb-1 duration-300 w-full"
            >
              View Details
              <span className="text-lg">
                <MdOutlineLabelImportant />
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="max-w-80 py-2 flex flex-col gap-1 px-2">
        <div className="font-titleFont">
          <h2 className="text-lg text-primeColor font-medium text-xs md:text-[18px]">
            {props.productName}
          </h2>
          <p className="text-[#767676] text-[18px]">
            {currency.format(props.price || props?.sizes[0]?.price, {
              code: "INR",
            })}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Product;
