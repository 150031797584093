import React from "react";
import { ImCross } from "react-icons/im";
import { useDispatch } from "react-redux";

import { discount } from "../../utils/discount";
import currency from "currency-formatter";
import { decQuantity, incQuantity, removeItem } from "../../store/reducers/cartReducer";

const ItemCard = ({ item }) => {
  const dispatch = useDispatch();
  return (
    <div className="w-full grid grid-cols-6 border ">
      <div className="flex col-span-5 mdl:col-span-2 items-center gap-4 ml-4">
        
        <img className="w-28 h-28"  src={`/images/${item.images[0]}`} alt="productImage" />
        <h4 className="font-titleFont fontstyleclass" style={{fontSize: '16px'}}>{item.title}</h4>


      </div>
      <div className="col-span-5 mdl:col-span-4 flex items-center justify-between py-4 mdl:py-0 px-4 mdl:px-0 gap-6 mdl:gap-0">
      <span className="fontstyleclass flex w-1/3">{item.size}</span>
      <span className="fontstyleclass flex w-1/3"> {currency.format(
                           item?.shipping,
                            {
                              code: "INR",
                            }
                          )}</span>

        <div className="flex w-1/3 items-center text-lg fontstyleclass ">
        {currency.format(
                            discount(item.price, item.discount),
                            {
                              code: "INR",
                            }
                          )}
        </div>
        <div className="w-1/3 flex items-center gap-6 text-lg">
          <span
            onClick={() => dispatch(decQuantity(item._id))}
            className="w-8 h-8  text-2xl flex items-center justify-center hover:bg-gray-300 cursor-pointer duration-300 border-[1px] border-gray-300 hover:border-gray-300"
          >
            -
          </span>
          <p style={{marginBottom:"0px"}}>{item.quantity}</p>
          <span
            onClick={() => dispatch(incQuantity(item._id))}
            className="w-8 h-8  text-2xl flex items-center justify-center hover:bg-gray-300 cursor-pointer duration-300 border-[1px] border-gray-300 hover:border-gray-300"
          >
            +
          </span>
        </div>
        <div className="w-1/3 flex items-center font-titleFont font-bold text-lg">
          <p>
          {currency.format(
                            Number(item.quantity * discount(item.price, item.discount)) + Number(item.shipping || 0),
                            {
                              code: "INR",
                            }
                          )}
            </p>
        </div>

        <ImCross
          onClick={() =>
            {if (window.confirm("Are you sure you want to delete this item?")) {
              dispatch(removeItem(item._id));
            }
            }}
          className="text-primeColor hover:text-red-500 duration-300 cursor-pointer"
        />
      </div>
    </div>
  );
};

export default ItemCard;
