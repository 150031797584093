import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const brandService = createApi({
    reducerPath: 'brands',
    tagTypes: 'brands',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://thebookwale.com/api/api/',
        // https://thebookwale.com/api/api/
        prepareHeaders: (headers, { getState }) => {
            const reducers = getState();
            const token = reducers?.authReducer?.adminToken;
            headers.set('authorization', token ? `Bearer ${token}` : '');
            return headers;
        }
    }),
    endpoints: (builder) => {
        return {
            createBrand: builder.mutation({
                query: (data) => ({
                    url: '/brands', // Match the Express route path
                    method: 'POST',
                    body: data
                }),
                invalidatesTags: ['brands']
            }),
            updateBrand: builder.mutation({
                query: ({id,data}) => ({
                    url: `/brands/${id}`,
                    method: 'PUT',
                    body: data
                }),
                invalidatesTags: ['brands']
            }),
            deleteBrand: builder.mutation({
                query: (id) => ({
                    url: `/brands/${id}`,
                    method: 'DELETE'
                }),
                invalidatesTags: ['brands']
            }),
            getBrands: builder.query({
                query: (page) => ({
                    url: `/brands`,
                    method: 'GET'
                }),
                providesTags: ['brands']
            }),
            getBrand: builder.query({
                query: (id) => ({
                    url: `/brands/${id}`,
                    method: 'GET'
                }),
                providesTags: ['brands']
            })
        }
    }
});

export const {
    useCreateBrandMutation,
    useDeleteBrandMutation,
    useUpdateBrandMutation,
    useGetBrandsQuery,
    useGetBrandQuery
} = brandService;

export default brandService;
