import React from "react";
import Banner from "../../components/Banner/Banner";
import BannerBottom from "../../components/Banner/BannerBottom";
import BestSellers from "../../components/home/BestSellers/BestSellers";
import NewArrivals from "../../components/home/NewArrivals/NewArrivals";
import Sale from "../../components/home/Sale/Sale";
import SpecialOffers from "../../components/home/SpecialOffers/SpecialOffers";
import YearProduct from "../../components/home/YearProduct/YearProduct";
import { useGetBrandsQuery } from "../../store/services/brandService";
import BrandCard from "../../components/home/BrandCard/BrandCard";
import { Link } from "react-router-dom";
import Categories from "../../components/Categories/Categories";
import { useAllCategoriesQuery, useNonAccessoryCategoriesQuery, useRandomCategoriesQuery } from "../../store/services/categoryService";
import FeatureBlock from "../../components/FeatureBlock/FeatureBlock";
import { Toaster } from "react-hot-toast";
import HomeCategories from "../../components/home/Categories";
import { useAllProductTypesQuery } from "../../store/services/productTypeService";

const Home = () => {
  const { data, isFetching } = useNonAccessoryCategoriesQuery();
  const { data: brandsdata, isLoading, error } = useGetBrandsQuery();
  const { data: allproducttypes = [], isFetching: fetchingtypes } = useAllProductTypesQuery();
  const brands = brandsdata?.brands;
  return (
    <div className="w-full mx-auto">
      <Banner />
      
      <Toaster position="top-right" reverseOrder={true} />



      <div className="max-w-container mx-auto px-2">
        <div className="my-container  xl:mb-16 fuckman" >


          <h3 className="text-3xl mb-3 font-semibold fontstyleclass" >
            Shop By Category
          </h3>

          <Categories />






          <div className="flex justify-between md:mb-6 mt-6">
  <h3 className="text-3xl md:mb-3 font-semibold">Schools</h3>
  <span className="capitalize">
    <Link to={`/schools`}>see all</Link>
  </span>
</div>
<div className="grid grid-cols-2 md:grid-cols-4 xl:grid-cols-6 gap-2">
  {!isLoading &&
    brands?.map((brand) => (
      <BrandCard key={`brand--key${brand.id}`} brand={brand} />
    ))}
</div>
</div>










        {!isFetching &&
          data?.categories?.length > 0 &&
          data?.categories?.map((category) => (
            <NewArrivals category={category} />
          ))}

        <FeatureBlock />

        <Sale />
      </div>
    </div>
  );
};

export default Home;
