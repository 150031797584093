import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const addressService = createApi({
  reducerPath: "addressService",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://thebookwale.com/api/api/", // Replace 'your_api_base_url' with your actual API base URL
  }),
  endpoints: (builder) => ({
    getAddressList: builder.query({
      query: () => "addresses", // Assuming the endpoint to fetch address list is '/addresses'
    }),
    addAddress: builder.mutation({
      query: (addressData) => ({
        url: "addresses", // Assuming the endpoint to add address is '/add-address'
        method: "POST",
        body: addressData,
      }),
    }),
    updateAddress: builder.mutation({
      query: ({ id, addressData }) => ({
        url: `addresses/${id}`, // Assuming the endpoint to update address is '/update-address/:id'
        method: "PUT",
        body: addressData,
      }),
    }),
    deleteAddress: builder.mutation({
      query: (id) => ({
        url: `addresses/${id}`, // Assuming the endpoint to delete address is '/delete-address/:id'
        method: "DELETE",
      }),
    }),
    updateShippingAddress: builder.mutation({
      query: (id, userId) => ({
        url: `/update-shipping/${id}`,
        method: "PUT",
        body: { userId },
      }),
    }),
    getUserAddresses: builder.query({
      query: (userId) => `addresses/${userId}`, // Endpoint to fetch user addresses
    }),
  }),
});

export const {
  useGetAddressListQuery,
  useAddAddressMutation,
  useUpdateAddressMutation,
  useDeleteAddressMutation,
  useUpdateShippingAddressMutation,
  useGetUserAddressesQuery,
} = addressService;
export default addressService;
