

const data = [
	{
		id: 1,
		icon: "/images/feature/saving.svg",
		title: "Guaranteed Savings",
		description: "If you don’t make your membership fee in savings, we’ll refund the difference",
	},
	{
		id: 2,
		icon: "/images/feature/risk-free.svg",
		title: "Try it risk-free",
		description: "If you don’t make your membership fee in savings, we’ll refund the difference",
	},
	{
		id: 3,
		icon: "/images/feature/delivery.svg",
		title: "Super Fast Delivery",
		description: "If you don’t make your membership fee in savings, we’ll refund the difference",
	},
	{
		id: 4,
		icon: "/images/feature/product.svg",
		title: "1000+ products priced at cost",
		description: "If you don’t make your membership fee in savings, we’ll refund the difference",
	},
];


const FeatureBlock = ({
	className = "mb-12 md:mb-14 xl:mb-16",
}) => {
	return (
		<div
			className={`${className} bg-gray-200 feature-block-wrapper border border-gray-300 rounded-md w-full grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-10 md:gap-12 xl:gap-0 overflow-hidden py-12 xl:py-0 sm:px-4 md:px-8 lg:px-16 xl:px-0`}
		>
			{data?.map((item) => (
				<div
					className={
						`text-center border-gray-300 xl:border-l xl:first:border-s-0 px-4 sm:px-2.5 2xl:px-8 3xl:px-12 xl:py-12`

					}
				>
					<div className="mb-3.5 md:mb-5 xl:mb-3.5 2xl:mb-5 w-14 md:w-auto mx-auto">
						<img
							src={item.icon}
							alt={`${item.title}`}
							width="78"
							height="78"
						/>
					</div>
					<div className="-mb-1">
						<h3 className="text-heading text-base md:text-lg font-semibold mb-1.5 md:mb-2">
							{`${item.title}`}
						</h3>
						<p className="text-body text-xs md:text-sm leading-6 md:leading-7">
							{`${item.description}`}
						</p>
					</div>
				</div>
			))}
		</div>
	);
};

export default FeatureBlock;
