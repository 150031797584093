import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import { emptyCartimg } from "../../assets/images/index";
import ItemCard from "./ItemCard";
import { decQuantity, emptyCart, incQuantity, removeItem } from "../../store/reducers/cartReducer";
import currency  from "currency-formatter";
import { useSendPaymentMutation } from "../../store/services/paymentService";
import { HiOutlineChevronRight } from "react-icons/hi";

const Cart = () => {
  const { cart, total,totalshipping } = useSelector((state) => state.cart);
  const { userToken, user } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
 
  const navigate = useNavigate();
  const [doPayment, response] = useSendPaymentMutation();
  console.log("payment response", response);
  console.log(user);
 
  useEffect(() => {
    if (response?.isSuccess) {
      window.location.href = response?.data?.url;
    }
  }, [response]);
  
const proceednow = () =>{
  if (userToken) {
    navigate("/paymentgateway");
    
  } else {
    navigate("/signin");
  }
 
}

  return (
    <>
      <div className="w-full flex flex-col gap-3 p-2" style={{backgroundColor:"#f8f8f8"}} >
    
      <p className="text-sm font-normal text-lightText capitalize flex items-center " style={{marginBottom: "0px"}}>

        <span className="px-1 ">
          <HiOutlineChevronRight />
        </span>
        <span className="capitalize fontstyleclass text-primeColor">
          Home / Cart
        </span>
      </p>
    </div>
    <div className="max-w-container mx-auto px-4 pt-10">
      <div className="row">
          <div className="col-md-9">
          <div className="w-full h-12 bg-[#F5F7F7] text-primeColor hidden lgl:grid grid-cols-6 place-content-center px-6 text-m font-titleFont font-semibold">
            <span className="col-span-2">Product</span>
            <span className="fontstyleclass text-primeColor" >Size</span>
            <span className="fontstyleclass text-primeColor" >Delivery</span>
            <span className="fontstyleclass text-primeColor">Price</span>
            <span className="fontstyleclass text-primeColor">Quantity</span>
            <span className="fontstyleclass text-primeColor">Sub Total</span>
 
 </div>
          <div >
            {cart.map((item) => (
              <div key={item._id}>
                <ItemCard item={item} />
              </div>
            ))}
          </div>

          <button
            onClick={() => dispatch(emptyCart())}
            className="mt-4 p-2 px-10 bg-red-500 text-white font-semibold uppercase hover:bg-red-700 duration-300"
          >
            Reset cart
          </button>
          </div>
          <div className="col-md-3">
          {cart.length > 0 ? (
        <div className="pb-20">
         



          <div className="shopping-cart">
          <div className="shopping-cart__totals-wrapper">
          <div className="sticky-content">
            <div className="shopping-cart__totals">
              <h3>Cart Totals</h3>
              <table className="cart-totals">
                <tbody>
                  <tr>
                    <th>Subtotal</th>
                    <td style={{textAlign:"end"}} > {currency.format(
                            total,
                            {
                              code: "INR",
                            }
                          )}</td>
                  </tr>
                  <tr>
                    <th>Shipping Charges</th>
                    <td style={{textAlign:"end"}}>   {currency.format(
                            totalshipping,
                            {
                              code: "INR",
                            }
                          )}</td>
                  </tr>
                  {/* <tr>
                    <th>Shipping</th>
                    <td style={{textAlign:"end"}}>
                      <div className="form-check">
                        <label className="form-check-label" for="free_shipping">Free shipping</label>
                      </div>

                      <div>Shipping to AL.</div>
                      <div>
                        <a href="#" className="menu-link menu-link_us-s">CHANGE ADDRESS</a>
                      </div>
                    </td>
                  </tr> */}
                  <tr>
                    <th>Coupon</th>
                    <td >
                      <div className="form-check">
                      <input
                className=" h-8 px-4 border text-primeColor text-sm outline-none border-gray-400"
                type="text"
                placeholder="Coupon Number"
              />
                      </div>

                     
                      <div style={{textAlign:"end"}}>
                        <a href="#" className="menu-link menu-link_us-s">APPLY COUPON</a>
                      </div>
                    </td>
                  </tr>
                  {/* <tr>
                    <th>GST</th>
                    <td style={{textAlign:"end"}}>₹ 19</td>
                  </tr> */}
                  <tr>
                    <th>Total</th>
                    <td style={{textAlign:"end"}}>  {currency.format(
                            total + totalshipping,
                            {
                              code: "INR",
                            }
                          )}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="mobile_fixed-btn_wrapper">
            
                  <button onClick={() => proceednow()} className="w-52 h-10 bg-primeColor text-white hover:bg-black duration-300">
                    Proceed to Checkout
                  </button>
            
            </div>
          </div>
        </div>
        </div>


        </div>
      ) : (
        <motion.div
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.4 }}
          className="flex flex-col mdl:flex-row justify-center items-center gap-4 pb-20"
        >
          <div>
            <img
              className="w-80 rounded-lg p-4 mx-auto"
              src={emptyCartimg}
              alt="emptyCartimg"
            />
          </div>
          <div className="max-w-[500px] p-4 py-8 bg-white flex gap-4 flex-col items-center rounded-md shadow-lg">
            <h1 className="font-titleFont text-xl font-bold uppercase">
              Your Cart feels lonely.
            </h1>
            <p className="text-sm text-center px-10 -mt-2">
              Your Shopping cart lives to serve. Give it purpose - fill it with
              books, electronics, videos, etc. and make it happy.
            </p>
            <Link to="/">
              <button className="bg-primeColor rounded-md cursor-pointer hover:bg-black active:bg-gray-900 px-8 py-2 font-titleFont font-semibold text-lg text-gray-200 hover:text-white duration-300">
                Continue Shopping
              </button>
            </Link>
          </div>
        </motion.div>
      )}
          </div>
      </div>
      
    </div></>
  );
};

export default Cart;
