import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from 'react-hot-toast';
import { clearMessage } from "../../store/reducers/globalReducer";
import Wrapper from "./Wrapper";
import { useGetBrandsQuery, useDeleteBrandMutation } from "../../store/services/brandService";
import ScreenHeader from "../../components/ScreenHeader";
import Spinner from "../../components/Spinner";
import Pagination from "../../components/Pagination";

const Brands = () => {
    let { page } = useParams();
    if (!page) {
        page = 1;
    }

    const { data = [], isFetching } = useGetBrandsQuery(page);
    const { success } = useSelector(state => state.global);
    const dispatch = useDispatch();

    useEffect(() => {
        if (success) {
            toast.success(success);
        }
        return () => {
            dispatch(clearMessage());
        }
    }, []);

    const [deleteBrand, response] = useDeleteBrandMutation();

    const deleteBrandHandler = id => {
        if (window.confirm("Are you sure you want to delete this brand?")) {
            deleteBrand(id);
        }
    }

    return (
        <Wrapper>
            <ScreenHeader>
                <Link to="/dashboard/create-brand" className="btn-dark">Add School</Link>
                <Toaster position="top-right" />
            </ScreenHeader>
            {!isFetching ? data?.brands?.length > 0 ? (
                <div>
                    <table className="w-full bg-gray-900 rounded-md">
                        <thead>
                            <tr className="border-b border-gray-800 text-left">
                                <th className="p-3 uppercase text-sm font-medium text-gray-500">Name</th>
                                <th className="p-3 uppercase text-sm font-medium text-gray-500">Logo</th>
                                <th className="p-3 uppercase text-sm font-medium text-gray-500">Edit</th>
                                <th className="p-3 uppercase text-sm font-medium text-gray-500">Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.brands?.map(brand => (
                                
                                <tr className="odd:bg-gray-800" key={brand._id}>
                                    <td className="p-3 capitalize text-sm font-normal text-gray-400">{brand.name}</td>
                                    <td className="p-3 capitalize text-sm font-normal text-gray-400">
                                        <img src={`${brand.image.original}`} alt={brand.name} className="w-20 h-20 rounded-md object-cover" />
                                    </td>
                                    <td className="p-3 capitalize text-sm font-normal text-gray-400">
                                        <Link to={`/dashboard/edit-schools/${brand._id}`} className="btn btn-warning">Edit</Link>
                                    </td>
                                    <td className="p-3 capitalize text-sm font-normal text-gray-400">
                                        <span className="btn btn-danger cursor-pointer" onClick={() => deleteBrandHandler(brand._id)}>Delete</span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <Pagination page={parseInt(page)} perPage={data.perPage} count={data.count} path="dashboard/brands" />
                </div>
            ) : 'No brands!' : <Spinner />}
        </Wrapper>
    )
}

export default Brands;
