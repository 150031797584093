import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { MdClose } from "react-icons/md";
import { HiMenuAlt2 } from "react-icons/hi";
import { motion } from "framer-motion";
import { logo, logoLight } from "../../../assets/images";
import Image from "../../designLayouts/Image";
import { navBarList, paginationItems } from "../../../constants";
import Flex from "../../designLayouts/Flex";
import { useAllCategoriesQuery, useNonAccessoryCategoriesQuery } from "../../../store/services/categoryService";
import { useSelector } from "react-redux";
import { FaSearch, FaUser, FaCaretDown, FaShoppingCart } from "react-icons/fa";
import { useSearchProductsQuery } from "../../../store/services/homeProducts";
import HTMLReactParser from "html-react-parser";
import html2plaintext from "html2plaintext";
import currency  from "currency-formatter";

const Header = () => {
  const [showMenu, setShowMenu] = useState(true);
  const [sidenav, setSidenav] = useState(false);
  const [category, setCategory] = useState(false);
  const [brand, setBrand] = useState(false);
  const location = useLocation();
  const { data:catdat } = useNonAccessoryCategoriesQuery();
  useEffect(() => {
    let ResponsiveMenu = () => {
      if (window.innerWidth < 667) {
        setShowMenu(false);
      } else {
        setShowMenu(true);
      }
    };
    ResponsiveMenu();
    window.addEventListener("resize", ResponsiveMenu);
  }, []);

  const { userToken, user } = useSelector((state) => state.authReducer);
  const { items } = useSelector((state) => state.cart);
  const [show, setShow] = useState(false);
  const [showUser, setShowUser] = useState(false);
  const navigate = useNavigate();
  // const ref = useRef();
  // useEffect(() => {
  //   document.body.addEventListener("click", (e) => {
  //     if (ref.current.contains(e.target)) {
  //       setShow(true);
  //     } else {
  //       setShow(false);
  //     }
  //   });
  // }, [show, ref]);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [showSearchBar, setShowSearchBar] = useState(false);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };
  const { data: searchdata, isLoading, error } = useSearchProductsQuery({keyword:searchQuery,page:1});

  useEffect(() => {
    if (searchdata) {
      setFilteredProducts(searchdata.products);
    }
  }, [searchdata]);

  const { data, isFetching } = useAllCategoriesQuery();
  return (
    <div className="w-full bg-white sticky top-0 z-50 border-b-[1px] border-b-gray-200">
      <div className="top-header desktop-only">
        <div className="container">
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6">
              <h6 style={{ fontSize: "14px" }}>
              10000+ Products&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;Trusted Sellers&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;Quick Delivery
              </h6>
            </div>
            <div className="col-md-3"></div>
          </div>
        </div>
      </div>
      <nav className="h-full px-4 max-w-container mx-auto relative" style={{ paddingTop: "10px" }}>
        <Flex className="flex items-center justify-between h-full">
          <Link to="/" className="col-md-1">
            <div>
              <Image className="object-cover" imgSrc="/images/logo/2.png" style={{ width: "150px", maxWidth: "100%", height: "auto" }}/>
            </div>
          </Link>
  
          {/*  */}
          <div className="hidden md:flex relative w-full lg:w-[600px]  text-base text-primeColor bg-white flex items-center gap-2 justify-between px-6 rounded-xl" style={{ border: "1px solid #e1e1e1",height: "40px" }}>
            <input
              className="flex-1 h-full outline-none placeholder:text-[#C4C4C4] placeholder:text-[14px]"
              type="text"
              onChange={handleSearch}
              value={searchQuery}
              placeholder="Search your products here"
            />
            <FaSearch className="w-5 h-5" color="#e1e1e1" />
            {searchQuery && (
              <div className={`w-full mx-auto h-96 bg-white top-16 absolute left-0 z-50 overflow-y-scroll shadow-2xl scrollbar-hide cursor-pointer`}>
                {!isLoading &&
                  filteredProducts?.map((item) => (
                    <div
                      onClick={() =>
                        navigate(`/product/${item._id}`, { state: { item: item } }) &
                        setShowSearchBar(true) &
                        setSearchQuery("")
                      }
                      key={item._id}
                      className="max-w-[600px] h-28 bg-gray-100 mb-3 flex items-center gap-2"
                    >
                      <img className="w-24" src={`/images/${item.images[0]}`} alt="productImg" />
                      <div className="flex flex-col ">
                        <p className="font-semibold text-lg">{item.title}</p>
                        <p className="text-sm">
                          Price:{" "}
                          <span className="text-primeColor font-semibold">
                            {currency.format(item.price, { code: "INR" })}
                          </span>
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
  
          <div className="hidden md:flex gap-4 mt-2 lg:mt-0 items-center pr-6 relative">
            {!userToken ? (
              <button
                onClick={() => setShowUser(!showUser)}
                className="flex items-center justify-center px-4 py-2 bg-[#0171bc] text-white rounded-md hover:bg-opacity-90 transition-colors duration-300"
              >
                <FaUser className="mr-2" />
                <span>Login</span>
                <FaCaretDown className="ml-2" />
              </button>
            ) : (
              <Link to="/profile" className="nav-link">
                <button className="px-4 py-2 bg-[#0171bc] text-white rounded-md hover:bg-opacity-90 transition-colors duration-300">
                  {user?.name}
                </button>
              </Link>
            )}
            {showUser && (
              <motion.ul
                initial={{ y: 30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="absolute top-12 left-0 z-50 bg-white w-44 text-primeColor h-auto p-2 rounded-md shadow-lg"
              >
                <Link to="/signin">
                  <li className="px-4 py-2 hover:bg-gray-100 rounded-md cursor-pointer">
                    Login
                  </li>
                </Link>
                <Link onClick={() => setShowUser(false)} to="/signup">
                  <li className="px-4 py-2 hover:bg-gray-100 rounded-md cursor-pointer">
                    Sign Up
                  </li>
                </Link>
              </motion.ul>
            )}
            <Link to="/cart">
              <button className="relative flex items-center justify-center px-4 py-2 bg-white border border-[#0171bc] rounded-md hover:bg-gray-50 transition-colors duration-300">
                <FaShoppingCart className="text-gray-600 mr-2" />
                <span className="text-gray-600">Cart</span>
                <span className="absolute -top-2 -right-2 text-xs w-5 h-5 flex items-center justify-center rounded-full bg-[#0171bc] text-white">
                  {items > 0 ? items : 0}
                </span>
              </button>
            </Link>
          </div>
  
          <HiMenuAlt2
            onClick={() => setSidenav(!sidenav)}
            className="inline-block md:hidden cursor-pointer w-8 h-6"
          />
        </Flex>
  
        {sidenav && (
  <div className="fixed top-0 right-0 w-full h-screen bg-black text-gray-200 bg-opacity-80 z-50">
  <motion.div
    initial={{ x: 300, opacity: 0 }}
    animate={{ x: 0, opacity: 1 }}
    transition={{ duration: 0.5 }}
    className="w-[80%] h-full absolute right-0"
  >
    <div className="w-full h-full bg-primeColor p-6">
    
      <img className="w-28 mb-6" src="/images/logo/2.png" alt="logoLight" />
      <ul className="text-gray-200 flex flex-col gap-2 items-end">
        {navBarList.map((item) => (
          <li
            className="font-normal hover:font-bold items-center text-lg text-gray-200 hover:underline underline-offset-[4px] decoration-[1px] hover:text-white md:border-r-[2px] border-r-gray-300 hoverEffect last:border-r-0 text-right"
            key={item._id}
          >
            <NavLink
              to={item.link}
              state={{ data: location.pathname.split("/")[1] }}
              onClick={() => setSidenav(false)}
              className="text-right"
              >
                {item.title}
              </NavLink>
            </li>
          ))}
          {/*                  
          <li>
            <h1
              onClick={() => setBrand(!brand)}
              className="flex justify-between text-base cursor-pointer items-center font-titleFont mb-2"
            >
              Shop by Brand
              {brand ? "-" : "+"}
            </h1>
            {brand && (
              <motion.ul
                initial={{ y: 15, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.4 }}
                className="text-sm flex flex-col gap-1"
              >
                <li>New Arrivals</li>
                <li>Gadgets</li>
                <li>Accessories</li>
                <li>Electronics</li>
                <li>Others</li>
              </motion.ul>
            )}
          </li> */}
        </ul>
        <MdClose
          onClick={() => setSidenav(false)}
          className="w-8 h-8 border-[1px] border-gray-300 absolute top-2 -left-10 text-gray-300 text-2xl flex justify-center items-center cursor-pointer hover:border-red-500 hover:text-red-500 duration-300"
        />
      </div>
    </motion.div>
  </div>
)}


<div className="headerbottom" style={{borderTop:'1px solid ghostwhite',
    padding: '5px 10px',
    marginTop: '15px'}}>
  <div className="container">
    <div className="row">
    <div className="hidden md:flex flex-1 mx-4">
            {showMenu && (
              <motion.ul
                initial={{ y: 30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="flex items-center w-auto z-50 p-0 gap-2"
              >
                {navBarList.map(({ _id, title, link }) => (
                  <NavLink
                    key={_id}
                    className={({ isActive }) =>
                      `flex font-normal hover:font-bold w-20 h-6 justify-center items-center px-12 text-base ${
                        isActive
                          ? "bg-[#0171bc] text-white"
                          : "text-[#767676] hover:underline underline-offset-[4px] hover:text-[#262626]"
                      } hoverEffect home-tag`
                    }
                    to={link}
                    state={{ data: location.pathname.split("/")[1] }}
                  >
                    <li style={{ textTransform: "uppercase", fontSize: '14px' }}>{title}</li>
                  </NavLink>
                ))}
                {catdat?.categories.map((category, index) => (
                  <NavLink
                    key={category._id}
                    className={({ isActive }) =>
                      `flex font-normal hover:font-bold w-20 h-6 justify-center items-center px-12 text-base ${
                        isActive
                          ? "bg-[#0171bc] text-white"
                          : "text-[#767676] hover:underline underline-offset-[4px] hover:text-[#262626]"
                      } hoverEffect home-tag`
                    }
                    to={`/shop/${category.name}`}
                  >
                    <li style={{ textTransform: "uppercase", fontSize: '14px' }}>{category.name}</li>
                  </NavLink>
                ))}
              </motion.ul>
            )}
          </div>
    </div>
  </div>
</div>



  
  <div className="md:hidden flex justify-between items-center p-3 border-t text-base text-primeColor bg-white flex items-center gap-2 justify-between px-6 rounded-xl mt-2" style={{ border: "1px solid #e1e1e1" }}>

  <input
              className="flex-1 h-full outline-none placeholder:text-[#C4C4C4] placeholder:text-[14px]"
              type="text"
              onChange={handleSearch}
              value={searchQuery}
              placeholder="Search your products here"
            />
            <FaSearch className="w-5 h-5" color="#e1e1e1" />
            {searchQuery && (
              <div className={`w-full mx-auto h-96 bg-white top-36 absolute left-0 z-50 overflow-y-scroll shadow-2xl scrollbar-hide cursor-pointer`}>
                {!isLoading &&
                  filteredProducts?.map((item) => (
                    <div
                      onClick={() =>
                        navigate(`/product/${item._id}`, { state: { item: item } }) &
                        setShowSearchBar(true) &
                        setSearchQuery("")
                      }
                      key={item._id}
                      className="max-w-[600px] h-28 bg-gray-100 mb-3 flex items-center gap-2"
                    >
                      <img className="w-24" src={`/images/${item.images[0]}`} alt="productImg" />
                      <div className="flex flex-col ">
                        <p className="font-semibold text-lg">{item.title}</p>
                        <p className="text-sm">
                          Price:{" "}
                          <span className="text-primeColor font-semibold">
                            {currency.format(item.price, { code: "INR" })}
                          </span>
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            )}
  <div className="flex items-center">
  <Link to="/cart" className="ml-4 mr-4">
              <div className="relative">
                <FaShoppingCart />
                <span className="absolute font-titleFont top-3 -right-2 text-xs w-4 h-4 flex items-center justify-center rounded-full bg-primeColor text-white">
                  {items > 0 ? items : 0}
                </span>
              </div>
            </Link>
  {!userToken && (
              <div onClick={() => setShowUser(!showUser)} className="flex">
                <FaUser />
                <FaCaretDown />
              </div>
            )}
            {userToken && (
              <Link to="/profile" className="nav-link">
                {user?.name}
              </Link>
            )}
            {showUser && (
              <motion.ul
                initial={{ y: 30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="absolute top-40 right-0 z-50 bg-primeColor w-44 text-[#767676] h-auto p-4 pb-6"
              >
                <Link to="/signin">
                  <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                    Login
                  </li>
                </Link>
                <Link onClick={() => setShowUser(false)} to="/signup">
                  <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                    Sign Up
                  </li>
                </Link>
              </motion.ul>
            )}
    
  </div>
</div>
</nav>
</div>
);


};

export default Header;
