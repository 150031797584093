import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const categoryService = createApi({
  reducerPath: "category",
  tagTypes: "categories",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://thebookwale.com/api/api/",
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = reducers?.authReducer?.adminToken;
      console.log(token);
      headers.set("authorization", token ? `Bearer ${token}` : "");
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {
      create: builder.mutation({
        query: (name) => {
          return {
            url: "create-category",
            method: "POST",
            body: name,
          };
        },
        invalidatesTags: ["categories"],
      }),
      updateCategory: builder.mutation({
        query: ({id,data}) => {
          return {
            url: `update-category/${id}`,
            method: "PUT",
            body: data,
          };
        },
        invalidatesTags: ["categories"],
      }),
      deleteCategory: builder.mutation({
        query: (id) => {
          return {
            url: `delete-category/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["categories"],
      }),
      get: builder.query({
        query: (page) => {
          return {
            url: `categories/${page}`,
            method: "GET",
          };
        },
        providesTags: ["categories"],
      }),
      fetchCategory: builder.query({
        query: (id) => {
          return {
            url: `fetch-category/${id}`,
            method: "GET",
          };
        },
        providesTags: ["categories"],
      }),
      allCategories: builder.query({
        query: () => {
          return {
            url: "allcategories",
            method: "GET",
          };
        },
      }),
      randomCategories: builder.query({
        query: () => {
          return {
            url: "random-categories",
            method: "GET",
          };
        },
      }),
      accessoryCategories: builder.query({  // New endpoint for accessory categories
        query: () => {
          return {
            url: "accessory-categories",
            method: "GET",
          };
        },
        providesTags: ["categories"],
      }),
      nonAccessoryCategories: builder.query({  // New endpoint for non-accessory categories
        query: () => {
          return {
            url: "non-accessory-categories",
            method: "GET",
          };
        },
        providesTags: ["categories"],
      }),
    };
  },
});
export const {
  useCreateMutation,
  useGetQuery,
  useFetchCategoryQuery,
  useAllCategoriesQuery,
  useRandomCategoriesQuery,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
  useAccessoryCategoriesQuery,  // New hook for accessory categories
  useNonAccessoryCategoriesQuery,  // New hook for non-accessory categories
} = categoryService;
export default categoryService;
