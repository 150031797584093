import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import { useCreateContactMutation } from "../../store/services/contactService";
import { useSelector } from "react-redux";
import { useGetContactUsByIdQuery } from "../../store/services/contactusService";

const Contact = () => {
	const { contactusid } = useSelector((state) => state.authReducer);
	const { data:contactusdata, isFetching:contactusfetch } = useGetContactUsByIdQuery(contactusid);
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");
  useEffect(() => {
    setPrevLocation(location.state.data);
  }, [location]);

  const [clientName, setClientName] = useState("");
  const [email, setEmail] = useState("");
  const [messages, setMessages] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [errClientName, setErrClientName] = useState("");
  const [errEmail, setErrEmail] = useState("");
  const [errMessages, setErrMessages] = useState("");

  const [createContact, { isLoading }] = useCreateContactMutation(); // Use the createContact mutation from the contact service

  const handleName = (e) => {
    setClientName(e.target.value);
    setErrClientName("");
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
    setErrEmail("");
  };

  const handleMessages = (e) => {
    setMessages(e.target.value);
    setErrMessages("");
  };

  const EmailValidation = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
  };

  const handlePost = async (e) => {
    e.preventDefault();
    if (!clientName) {
      setErrClientName("Enter your Name");
    }
    if (!email) {
      setErrEmail("Enter your Email");
    } else {
      if (!EmailValidation(email)) {
        setErrEmail("Enter a Valid Email");
      }
    }
    if (!messages) {
      setErrMessages("Enter your Messages");
    }
    if (clientName && email && EmailValidation(email) && messages) {
      try {
        const response = await createContact({ name: clientName, email, message:messages });
        if (response.error) {
          // Handle error response
          console.error("Error:", response.error);
        } else {
          // Clear form fields and set success message
          setSuccessMsg(
            `Thank you dear ${clientName}, Your messages has been received successfully. Further details will be sent to you by your email at ${email}.`
          );
          setClientName("");
          setEmail("");
          setMessages("");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <>
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="Contact" prevLocation={prevLocation} />
      



        <div className="full-row">
        <div className="container">
            <div className="row">
            {successMsg ? (
        <p className="pb-20 w-96 font-medium text-green-500">{successMsg}</p>
      ) : (
        <form className="col-lg-6 col-md-6">
          <h1 className="font-titleFont font-semibold text-3xl">
            Fill up a Form
          </h1>
          <div className="w-[500px] h-auto py-6 flex flex-col gap-6">
            <div>
              <p className="text-base font-titleFont font-semibold px-2">
                Name
              </p>
              <input
                onChange={handleName}
                value={clientName}
                className="w-full py-1 border-b-2 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor"
                type="text"
                placeholder="Enter your name here"
              />
              {errClientName && (
                <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2 flex items-center gap-1">
                  <span className="text-sm italic font-bold">!</span>
                  {errClientName}
                </p>
              )}
            </div>
            <div>
              <p className="text-base font-titleFont font-semibold px-2">
                Email
              </p>
              <input
                onChange={handleEmail}
                value={email}
                className="w-full py-1 border-b-2 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor"
                type="email"
                placeholder="Enter your email here"
              />
              {errEmail && (
                <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2 flex items-center gap-1">
                  <span className="text-sm italic font-bold">!</span>
                  {errEmail}
                </p>
              )}
            </div>
            <div>
              <p className="text-base font-titleFont font-semibold px-2">
                Messages
              </p>
              <textarea
                onChange={handleMessages}
                value={messages}
                cols="30"
                rows="3"
                className="w-full py-1 border-b-2 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor resize-none"
                placeholder="Enter your message here"
              ></textarea>
              {errMessages && (
                <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2 flex items-center gap-1">
                  <span className="text-sm italic font-bold">!</span>
                  {errMessages}
                </p>
              )}
            </div>
            <button
              onClick={handlePost}
              disabled={isLoading}
              className="w-44 bg-primeColor text-gray-200 h-10 font-titleFont text-base tracking-wide font-semibold hover:bg-black hover:text-white duration-200"
            >
              {isLoading ? "Posting..." : "Post"}
            </button>
          </div>
        </form>
                  
      )} 



                <div className="col-lg-6 col-md-6">
                    <h3 className="down-line mb-5">Get In Touch</h3>
                    <div className="d-flex mb-3">
                        <ul>
                            <li className="mb-3">
                                <strong>Office Address :</strong><br/>{contactusdata?.address}                                  </li>
                            <li className="mb-3">
                                <strong>Contact Number :</strong><br/> {contactusdata?.phoneNumber},{contactusdata?.anotherPhoneNumber}                          </li>
                            <li className="mb-3">
                                <strong>Email Address :</strong><br/> {contactusdata?.emailAddress},{contactusdata?.anotherEmailAddress}                                  </li>
                            <li className="mb-3">
                                <strong>Working Hours :</strong><br/>   <div dangerouslySetInnerHTML={{ __html: contactusdata?.workingHours }} /> </li>
                        </ul>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
    
    
   

    </div>

<div className="full-row p-0">
<div className="container-fluid">
    <div className="row">
        <div className="col-md-12">
        <div>{<div dangerouslySetInnerHTML={{ __html: contactusdata?.googleMapLocationEmbedLink }} />}</div>            </div>
    </div>
</div>
</div></>
  );
};

export default Contact;
