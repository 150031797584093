import { configureStore } from "@reduxjs/toolkit";
import authService from "./services/authService";
import categoryService from "./services/categoryService";
import productService from "./services/productService";
import brandService from "./services/brandService";
import paymentService from "./services/paymentService";
import authReducer from "./reducers/authReducer";
import globalReducer from "./reducers/globalReducer";
import cartReducer from "./reducers/cartReducer";
import homeProducts from "./services/homeProducts";
import orderService from "./services/orderService";
import userOrdersService from "./services/userOrdersService";
import { setupListeners as setupRTKQueryListeners } from "@reduxjs/toolkit/query/react";
import addressService from "./services/addressService";
import contactService from "./services/contactService";
import newsletterSubscriptionService from "./services/newsletterSubscriptionService";
import contactUsService from "./services/contactusService";
import informationService from "./services/informationService";
import bannerImageService from "./services/bannerImageService";
import sizeService from "./services/sizeService";
import productTypeService from "./services/productTypeService";
const Store = configureStore({
  reducer: {
    [authService.reducerPath]: authService.reducer,
    [addressService.reducerPath]: addressService.reducer,
    [categoryService.reducerPath]: categoryService.reducer,
    [productService.reducerPath]: productService.reducer,
    [brandService.reducerPath]: brandService.reducer,
    [homeProducts.reducerPath]: homeProducts.reducer,
    [paymentService.reducerPath]: paymentService.reducer,
    [orderService.reducerPath]: orderService.reducer,
    [userOrdersService.reducerPath]: userOrdersService.reducer,
    [productTypeService.reducerPath]: productTypeService.reducer,
    [contactService.reducerPath]: contactService.reducer,
    [newsletterSubscriptionService.reducerPath]: newsletterSubscriptionService.reducer,
    [contactUsService.reducerPath]: contactUsService.reducer,
    [informationService.reducerPath]: informationService.reducer,
    [bannerImageService.reducerPath]: bannerImageService.reducer,
    [sizeService.reducerPath]: sizeService.reducer,
    authReducer: authReducer,
    global: globalReducer,
    cart:cartReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      [categoryService.middleware,
      productService.middleware,
      brandService.middleware,
      homeProducts.middleware,
      paymentService.middleware,
      orderService.middleware,
      userOrdersService.middleware,
      productTypeService.middleware,
      addressService.middleware,
      contactService.middleware,
      newsletterSubscriptionService.middleware,
      contactUsService.middleware,
      informationService.middleware,
      bannerImageService.middleware,
      sizeService.middleware,
      authService.middleware]
    
    ),
});

export default Store;
