import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import ProductInfo from "../../components/pageProps/productDetails/ProductInfo";
import ProductsOnSale from "../../components/pageProps/productDetails/ProductsOnSale";

import { useGetProductQuery } from "../../store/services/productService";
import ProductLoader from "../../components/ProductLoader/ProductLoader";
import { Toaster } from "react-hot-toast";
import DetailsImage from "../../components/home/DetailsImage";
import NewArrivals from "../../components/home/NewArrivals/NewArrivals";
import Product from "../../components/home/Products/Product";
import ProductImagesSlider from "../../components/ProductGallery";

const ProductDetails = () => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");

  useEffect(() => {
    setPrevLocation(location.pathname);
  }, [location, ]);


  const { _id } = useParams();
  const { data, isFetching } = useGetProductQuery(_id);

  return (
    <div className="w-full mx-auto border-b-[1px] border-b-gray-300">
       <Toaster position="top-right" reverseOrder={false} />
      <div className="max-w-container mx-auto px-4">
        <div className="xl:-mt-10 -mt-7">
          <Breadcrumbs title="" prevLocation={prevLocation} />
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-6 gap-4 h-full -mt-5 xl:-mt-8 pb-10 p-4">
          {/* <div className="h-full">
            <ProductsOnSale category={data?.category} />
          </div> */}
          <div className="h-full  xl:col-span-3">
          {isFetching ? 
          <ProductLoader />:
        <div className=" xl:col-span-3">
           
            
            <ProductImagesSlider images={data?.images} />
         {/* {data.images && <DetailsImage image={[0]} />} */}
      </div>
          }
          </div>
          {isFetching ? (
          <ProductLoader />
        ) : (
          <div className="h-full w-full md:col-span-2 xl:col-span-3 xl:p-14 flex flex-col gap-6 justify-center">
            <ProductInfo productInfo={data} />
          </div>
        )}


        </div>

        <h3 className="text-3xl mb-3 font-semibold fontstyleclass " >
            Related Products 
          </h3>
          <ProductsOnSale category={data?.category} filterid={data?._id}/>


      </div>

    </div>
  );
};

export default ProductDetails;
