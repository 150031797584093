import React, { useState } from "react";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import Pagination from "../../components/pageProps/shopPage/Pagination";
import ProductBanner from "../../components/pageProps/shopPage/ProductBanner";
import ShopSideNav from "../../components/pageProps/shopPage/ShopSideNav";
import { useParams } from "react-router-dom";
import { useBrandProductsQuery, } from "../../store/services/homeProducts";
import ProductLoader from "../../components/ProductLoader/ProductLoader";
import Filter from "../../components/pageProps/shopPage/shopBy/SideFilter";

const ShopBybrand = () => {
  const { name, page = 1, slug, class: classname, type, producttype } = useParams();
  const [filters, setFilters] = useState({});
  const { data, isFetching } = useBrandProductsQuery({
    name: slug,
    page: parseInt(page),
    ...filters
  });
  const [itemsPerPage, setItemsPerPage] = useState(12);
  const itemsPerPageFromBanner = (itemsPerPage) => {
    setItemsPerPage(itemsPerPage);
  };

  const handleFilterChange = (data) => {
    setFilters(data);
  };

  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title={"Products For " + name} />
      {/* ================= Products Start here =================== */}
      <div className="w-full h-full flex pb-20 gap-10">
        <div className="w-[20%] lgl:w-[25%] hidden mdl:inline-flex h-full">
          <Filter
            onFilterChange={handleFilterChange}
          />
        </div>
        <div className="w-full mdl:w-[80%] lgl:w-[75%] h-full flex flex-col gap-10">
          <ProductBanner itemsPerPageFromBanner={itemsPerPageFromBanner} />
          {isFetching ? (
            <ProductLoader />
          ) : (
            <Pagination itemsPerPage={itemsPerPage} currentItems={data?.products} />
          )}
        </div>
      </div>
      {/* ================= Products End here ===================== */}
    </div>
  );
};

export default ShopBybrand;
