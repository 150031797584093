import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import { useSelector } from "react-redux";
import { useGetContactUsByIdQuery } from "../../store/services/contactusService";

const About = () => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");
  useEffect(() => {
    setPrevLocation(location.state.data);
  }, [location]);
  return (
    <div className="max-w-container mx-auto px-4">
      {/* <Breadcrumbs title="About" prevLocation={prevLocation} /> */}
      <div className="pb-10">
      <div className="full-row bg-dark overlay-dark py-5" style={{backgroundImage: 'url("https://johnfrancis.ca/en/wp-content/uploads/2021/08/1-1.jpg")', backgroundPosition: 'center center', backgroundSize: 'cover'}} id="fuma">
                <div className="container">
                    <div className="row text-center text-white">
                        <div className="col-12">
                            <h3 className="mb-2 text-white">ABOUT US</h3>
                        </div>
                        <div className="col-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-inline-flex bg-transparent p-0">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">ABOUT US</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            <div className="full-row p-0" style={{backgroundSize: 'auto',backgroundPosition: 'right bottom'}}>
                <div className="container-fluid">
                    <div className="row p-0" style={{alignItems: 'center'}}>
                        <div className="col-lg-6 order-lg-2">
                            <div className="connet" style={{padding: '30px'}}>
                                <h3>Short Story About Us</h3>

<p>
Welcome to Book Wale, your one-stop destination for quality uniforms that blend style, comfort, and durability. With a passion for providing exceptional attire solutions, Book Wale has been serving diverse clientele with premium uniform options since [year of establishment]. Our commitment to excellence is evident in every stitch, fabric choice, and design detail.
<br/>
At Book Wale, we understand the importance of professional appearance and the impact it has on confidence and performance. Whether you're a corporate entity, educational institution, healthcare facility, or hospitality establishment, we cater to your unique uniform needs with a wide range of customizable options.
<br/>
Backed by years of industry experience and a dedicated team of skilled professionals, we take pride in delivering superior quality uniforms that not only meet but exceed your expectations. Our relentless pursuit of customer satisfaction drives us to continually innovate and adapt to evolving trends and preferences.
<br/>
Beyond providing exceptional products, Book Wale is committed to fostering lasting relationships with our clients, built on trust, integrity, and reliability. We strive to create a seamless shopping experience, offering personalized assistance and support at every step of the journey.
<br/>
Thank you for choosing Book Wale as your trusted uniform partner. We look forward to dressing you for success and helping you make a lasting impression in your professional endeavors.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 order-lg-1 wow fadeIn md-mt-30 p-0" data-wow-delay="200ms" data-wow-duration="1000ms">
                            <div className="mt-md-30  position-relative" >
                                <img src="https://cdn.dotpe.in/longtail/store-items/7941426/TDYFH4P8.jpeg"/>
                               
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="connet" style={{padding: '30px'}}>
                                                            </div>
                        </div>
                    </div>
                </div>
            </div>
      </div>
    </div>
  );
};

export default About;
