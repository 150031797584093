import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  createRoutesFromElements,
  Route,
  ScrollRestoration,
  Routes,
  BrowserRouter,
  useLocation,
} from "react-router-dom";
import Footer from "./components/home/Footer/Footer";
import FooterBottom from "./components/home/Footer/FooterBottom";
import Header from "./components/home/Header/Header";
import HeaderBottom from "./components/home/Header/HeaderBottom";
import SpecialCase from "./components/SpecialCase/SpecialCase";
import About from "./pages/About/About";
import SignIn from "./pages/Account/SignIn";
import SignUp from "./pages/Account/SignUp";
import Cart from "./pages/Cart/Cart";
import Contact from "./pages/Contact/Contact";
import Home from "./pages/Home/Home";
import Journal from "./pages/Journal/Journal";
import Offer from "./pages/Offer/Offer";
import Payment from "./pages/payment/Payment";
import ProductDetails from "./pages/ProductDetails/ProductDetails";
import Shop from "./pages/Shop/Shop";
import "swiper/css";
import "swiper/css/pagination";
import ShopBybrand from "./pages/ShopByBrand/ShopbyBrand";
import UserRoute from "./routes/UserRoute";
import AdminLogin from "./pages/auth/AdminLogin";
import Products from "./pages/dashboard/Products";
import Public from "./routes/Public";
import Private from "./routes/Private";
import EditProduct from "./pages/dashboard/EditProduct";
import Categories from "./pages/dashboard/Categories";
import CreateCategory from "./pages/dashboard/CreateCategory";
import UpdateCategory from "./pages/dashboard/UpdateCategory";
import CreateProduct from "./pages/dashboard/CreateProduct";
import Brands from "./pages/dashboard/Brands";
import CreateBrand from "./pages/dashboard/CreateBrand";
import Orders from "./pages/dashboard/Orders";
import OrderDetails from "./pages/dashboard/OrderDetails";
import { useEffect } from "react";
import Profile from "./pages/Account/Profile";
import 'bootstrap/dist/css/bootstrap.min.css';
import UserOrderDetails from "./pages/Account/UserOrderDetails";
import AllUsers from "./pages/dashboard/AllUsers";
import Requests from "./pages/dashboard/Requests";
import OrderSummary from "./pages/dashboard/OrdersSummary";
import Subscribers from "./pages/dashboard/Subscribers";
import ContactUs from "./pages/dashboard/ContactUs";
import PolicyPages from "./pages/dashboard/PolicyPages";
import CreatePolicyPage from "./pages/dashboard/CreatePolicyPage";
import DynamicPage from "./pages/DynamicRoute.js/Dynamicpage";
import EditPolicyPage from "./pages/dashboard/EditPolicyPage";
import Sliders from "./pages/dashboard/Sliders";
import Logo from "./pages/dashboard/logo";
import EditBrand from "./pages/dashboard/EditBrand";
import ShopByClass from "./pages/ShopByClass/ShopByClass";
import Accessories from "./pages/Accessories/Accessories";
const Layout = () => {
  return (
    <div>
      <Header />
      {/* <HeaderBottom /> */}
      <SpecialCase />
      {/* <ScrollRestoration /> */}
      <Outlet />
      <Footer />
      <FooterBottom />
    </div>
  );
};
const App = () =>{
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on route change
  }, [location]);

  return(
    // <BrowserRouter>
    <Routes>
    <Route path="/" element={<Layout />}>
       <Route index element={<Home />} />
       <Route path=":id" element={<DynamicPage />} />
       <Route path="shop/:name" element={<Shop />} />
       <Route path="accessories" element={<Accessories />} />
       <Route path="school_class/:name/:slug/:producttype?" element={<ShopByClass />} />
       <Route path="school_shop/:name/:slug" element={<ShopBybrand />} />
       <Route path="about" element={<About />} />
       <Route path="contact" element={<Contact />} />
       <Route path="journal" element={<Journal />} />
       <Route path="schools/:producttype?" element={<Offer />} />
       <Route path="product/:_id" element={<ProductDetails />} />
       <Route path="user-order-details/:id" element={<UserOrderDetails />} />
       <Route path="cart" element={<Cart />} />
       <Route path="profile" element={<Profile />} />
       <Route path="paymentgateway" element={<Payment />} />
    </Route>
    <Route path="signup" element={<SignUp />} />
    <Route path="signin" element={<SignIn />} />
    <Route path="auth">
       <Route path="admin-login" element={<Public><AdminLogin /></Public>} />
    </Route>
    <Route path="dashboard">
       <Route path="sliders" element={<Private><Sliders /></Private>} />
       <Route path="logos" element={<Private><Logo /></Private>} />
       <Route path="products" element={<Private><Products /></Private>} />
       <Route path="all_users" element={<Private><AllUsers /></Private>} />
       <Route path="products/:page" element={<Private><Products /></Private>} />
       <Route path="edit-product/:id" element={<Private><EditProduct /></Private>} />
       <Route path="categories" element={<Private><Categories /></Private>} />
       <Route path="categories/:page" element={<Private><Categories /></Private>} />
       <Route path="create-category" element={<Private><CreateCategory /></Private>} />
       <Route path="update-category/:id" element={<Private><UpdateCategory /></Private>} />
       <Route path="create-product" element={<Private><CreateProduct /></Private>} />
       <Route path="brands" element={<Private><Brands /></Private>} />
       <Route path="edit-schools/:id" element={<Private><EditBrand /></Private>} />
       <Route path="create-brand" element={<Private><CreateBrand /></Private>} />
       <Route path="orders" element={<Orders />} />
       <Route path="requests" element={<Requests />} />
       <Route path="subscribers" element={<Subscribers />} />
       <Route path="contact-us" element={<ContactUs />} />
       <Route path="orders/:page" element={<Orders />} />
       <Route path="order-details/:id" element={<OrderDetails />} />
       <Route path="order-summary/:id" element={<OrderSummary />} />
       <Route path="policypages" element={<PolicyPages />} />
       <Route path="createpolicypages" element={<CreatePolicyPage />} />
       <Route path="policypages/:id" element={<EditPolicyPage />} />
    </Route>
   </Routes>
  //  </BrowserRouter>
  )
};

// function App() {
//   return (
//     <div className="font-bodyFont">
//       <RouterProvider router={router} />
//     </div>
//   );
// }

export default App;
